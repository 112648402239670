import {
  ArrowRight,
  CancelOutlined,
  Delete,
  DeleteForever,
  ErrorOutline,
  LocalFireDepartmentOutlined,
  MoreVert,
  Share,
  TextFields,
  WarningAmberOutlined,
  Link,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useState } from "react";
import api from "../../../services/api";
import AlertModal from "../../alertModal";
import ModalShareMap from "./modalShareMap";

function AlertIcons({ styles, number = 0, severity = "warning" }) {
  const color =
    severity === "disaster"
      ? "color.zabbixSeverty.disaster"
      : severity === "high"
      ? "color.zabbixSeverty.high"
      : severity === "average"
      ? "color.zabbixSeverty.average"
      : "color.zabbixSeverty.warning";
  return (
    <>
      <Box sx={[styles.iconsCircle, { backgroundColor: color }]}>
        <Typography sx={styles.subtitleAlert}>{number.toString()}</Typography>

        {severity === "disaster" ? (
          <LocalFireDepartmentOutlined
            sx={{
              ...styles.icons,
            }}
          />
        ) : severity === "high" ? (
          <CancelOutlined sx={styles.icons} />
        ) : severity === "average" ? (
          <ErrorOutline sx={styles.icons} />
        ) : (
          <WarningAmberOutlined sx={styles.icons} />
        )}
      </Box>
    </>
  );
}
function SubMenu({
  anchorElSubmenu,
  openSubmenu,
  setAnchorElSubmenu,
  handleClose,
  setOpenModalShare,
  handleCopy,
  hasLink = false,
  theme = {},
}) {
  return (
    <Popper
      //open={true}
      id="basic-menu-submenu"
      sx={{ zIndex: 1300 }}
      anchorEl={anchorElSubmenu}
      open={openSubmenu}
      onClose={() => {
        setAnchorElSubmenu(null);
      }}
      placement="right-start"
    >
      <Paper
        elevation={4}
        sx={{ bgcolor: theme.palette.mode === "dark" ? "#222222" : "none" }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenModalShare(true);
            }}
          >
            <ListItemIcon>
              <Share fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Compartilhar</ListItemText>{" "}
          </MenuItem>
        </MenuList>

        <MenuList>
          <MenuItem
            disabled={!hasLink}
            onClick={() => {
              handleClose();
              handleCopy();
            }}
          >
            <ListItemIcon>
              <Link fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Copiar Link</ListItemText>{" "}
          </MenuItem>
        </MenuList>
      </Paper>
    </Popper>
  );
}
export default function CardMapsHome({
  id = "",
  mapName = "Desconhecido",
  tipo = 0,
  hostsNames = [],
  hostsCounts = 0,
  popsCounts = 0,
  mapasCounts = 0,
  editMode = false,
  handleDelete,
  index = "",
  inGroup = true,
  updateTitleInMaps = () => {},
  setSnackbar = () => {},
  deleteMapsInMaps = () => {},
  updateMapTokenInMaps = () => {},
  alertas = [],
  mapas = [],
  pops = [],
  mapToken,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSubmenu, setAnchorElSubmenu] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlertsModal, setOpenAlertsModal] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newTitle, setNewTitle] = useState(mapName);
  const [loadinButtom, setLoadingButtom] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [openModalShare, setOpenModalShare] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const styles = useStyles(theme, editMode);

  const open = Boolean(anchorEl);
  const openSubmenu = Boolean(anchorElSubmenu);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopy = () => {
    const url = `${window.location.href}/public/${
      tipo === 0 ? "geo" : "top"
    }/${id}/${mapToken.token}`;
    navigator.clipboard.writeText(url);
    setSnackbar({
      children: "campo copiado!",
      severity: "info",
    });
  };
  async function handleApiUpdate() {
    try {
      setLoadingButtom(true);
      const response = await api.put("/easyMaps/map/updateTitle", {
        mapId: id,
        titulo: newTitle,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          chieldren: "Error: Não foi possível atualizar o título do mapa",
          severity: "error",
        });
      } else {
        if (response.data) {
          setOpenEditDialog(false);
          updateTitleInMaps(newTitle, index);
          setAnchorEl(null);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.response.status === 409
            ? " Título já existente"
            : error?.message || " Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingButtom(false);
    }
  }

  async function handleApiDelete() {
    try {
      try {
        setDisableButton(true);
        const response = await api.post("/easyMaps/map/delete", {
          mapId: id,

          logsLoginClienteId:
            JSON.parse(localStorage.getItem("logs"))?.id || "",
        });

        if (response.data.status === "Error") {
          setSnackbar({
            chieldren: "Error: Não foi possível excluir o mapa",
            severity: "error",
          });
        } else {
          if (response.data) {
            setOpenDeleteModal(false);
            deleteMapsInMaps(index);
            setAnchorEl(null);
          }
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível se conectar com o servidor"
          } `,
          severity: "error",
        });
      } finally {
        setLoadingButtom(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDisableButton(false);
    }
  }
  const handleMouseDown = (event) => {
    const url = `${window.location.origin}/easyMaps/${
      tipo === 0 ? "geo" : "top"
    }/${id}`;
    if (event.button === 1) {
      window.open(url, "_blank");
    }
  };
  return (
    <Card sx={{ ...styles.card, flexGrow: 1 }} variant="outlined" index={index}>
      {editMode && (
        <Tooltip title={"Clique para remover do grupo"} placement="top" arrow>
          <CardActionArea
            sx={styles.deleteCardAction}
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(id);
            }}
          >
            <DeleteForever sx={styles.deleteIcon} color={"primary"} />
          </CardActionArea>
        </Tooltip>
      )}
      <CardActionArea
        sx={styles.cardAction}
        onClick={() => {
          navigate(`/easyMaps/${tipo === 0 ? "geo" : "top"}/${id}`);
        }}
        onMouseDown={handleMouseDown}
      >
        <Box sx={styles.cardType}>
          {tipo === 0 ? (
            <Typography sx={styles.typeText}>{"GEO"}</Typography>
          ) : (
            <Typography sx={styles.typeText}>{"TOP"}</Typography>
          )}
        </Box>
        {!inGroup && (
          <IconButton
            id="options-button"
            sx={styles.options}
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
        )}

        <Stack gap={"5px"} mt={2}>
          <Typography sx={styles.title}>{mapName}</Typography>
          <Stack gap={"10px"} direction={"row"}>
            <Typography sx={styles.subtitle}>
              {hostsCounts + " Hosts"}
            </Typography>
            <Typography sx={styles.subtitle}>
              {mapasCounts + " Mapas"}
            </Typography>
            <Typography sx={styles.subtitle}>
              {popsCounts + " POP's"}
            </Typography>
          </Stack>
        </Stack>
        <Divider width={"100%"} orientation="horizontal" />
        {/*         <Box>
          <Typography sx={styles.hostsNames}>
            {hostsNames.map((name, index) => {
              const newName = name + (index === 0 ? " " : " ,");
              return newName;
            })}
          </Typography>
        </Box> */}
        <Stack direction="row" sx={styles.alerts}>
          {/* <Typography sx={styles.title}>{"Alertas"}</Typography>{" "} */}
          <Typography variant="textFieldTitle" pr={1}>
            {"Alertas:  "}
          </Typography>
          <Stack direction="row" sx={styles.alertsIcons}>
            <AlertIcons
              styles={styles}
              number={`${
                alertas.find((alert) => alert.severity === "5")?.value ?? 0
              }`}
              severity={"disaster"}
            />
            <AlertIcons
              styles={styles}
              number={`${
                alertas.find((alert) => alert.severity === "4")?.value ?? 0
              }`}
              severity={"high"}
            />
            <AlertIcons
              styles={styles}
              number={`${
                alertas.find((alert) => alert.severity === "3")?.value ?? 0
              }`}
              severity={"average"}
            />
            <AlertIcons
              styles={styles}
              number={`${
                alertas.find((alert) => alert.severity === "2")?.value ?? 0
              }`}
              severity={"warning"}
            />
          </Stack>
        </Stack>
      </CardActionArea>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenEditDialog(true);
            }}
          >
            <ListItemIcon>
              <TextFields fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Renomear</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              // handleClose();
              //setOpenModalShare(true);
            }}
            onMouseEnter={(event) => {
              setAnchorElSubmenu(event.currentTarget);
            }}
            onMouseLeave={(event) => {
              setAnchorElSubmenu(null);
            }}
          >
            <ListItemIcon>
              <Share fontSize="small" color="primary" />
            </ListItemIcon>
            <Stack
              direction={"row"}
              width={"150px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <ListItemText>Compartilhar</ListItemText>{" "}
              <ArrowRight fontSize="small" color="action" />
            </Stack>
            <SubMenu
              anchorElSubmenu={anchorElSubmenu}
              openSubmenu={openSubmenu}
              setAnchorElSubmenu={setAnchorElSubmenu}
              handleClose={handleClose}
              setOpenModalShare={setOpenModalShare}
              handleCopy={handleCopy}
              hasLink={!!mapToken?.token && mapToken.status}
              theme={theme}
            />
          </MenuItem>
          <Divider />

          <MenuItem
            onClick={() => {
              handleClose();
              setOpenDeleteModal(true);
            }}
          >
            <ListItemIcon>
              <Delete fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Excluir</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Renomear</DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Typography>Nome do mapa</Typography>
          <TextField
            value={newTitle}
            onChange={(event) => setNewTitle(event.target.value)}
            autoFocus
            autoComplete="off"
            placeholder="Nome do mapa"
            id="Nome do mapa"
            type="text"
            size="small"
            fullWidth
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                document.getElementById("confirmButton").click();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditDialog(false);
              setNewTitle(mapName);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            id="confirmButton"
            sx={styles.confirmButton}
            disabled={loadinButtom || newTitle.length === 0}
            onClick={handleApiUpdate}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <ModalShareMap
        id={id}
        tipo={tipo}
        mapName={mapName}
        openModalShare={openModalShare}
        setOpenModalShare={setOpenModalShare}
        mapas={[...mapas, ...pops.filter(({ mapRefId }) => !!mapRefId)]}
        setSnackbar={setSnackbar}
        updateMapTokenInMaps={updateMapTokenInMaps}
        index={index}
        mapToken={mapToken}
      />
      <AlertModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        confirmar={disableButton}
        handleButton={handleApiDelete}
        severity={"error"}
        singleButton={false}
      />
    </Card>
  );
}
//export { CardMapsHome };
