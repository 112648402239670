import { useState } from "react";
import {
  Stack,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Box,
  Button,
  Paper,
  Fade,
  LinearProgress,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import api from "../../../services/api";
const stylesBulkAdd = {
  container: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "30px",
  },
  paper: {
    maxWidth: "1550px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
    elevation: 3,
  },
  button: { height: "40px", minWidth: "120px", m: 1 },
  filter: { width: "180px" },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  porta: { width: "105px" },
  textField: { width: "230px" },
  titles: { fontWeight: 500 },
};
export default function AdicionaEmMassa(props) {
  const { predefinicoes, proxys, setSnackbar, loadingPredefinicoes } = props;
  const [hosts, setHosts] = useState([{ nome: "", ip: "" }]);
  const [fabricante, setFabricante] = useState("");
  const [modelo, setModelo] = useState("");
  const [funcao, setFuncao] = useState("");
  const [coletor, setColetor] = useState("");
  const [userSsh, setUserSsh] = useState("");
  const [portaSsh, setPortaSsh] = useState(22);
  const [senhaSsh, setSenhaSsh] = useState("");
  const [userTelnet, setUserTelnet] = useState("");
  const [portaTelnet, setPortaTelnet] = useState(23);
  const [senhaTelnet, setSenhaTelnet] = useState("");
  const [error, setError] = useState("");
  const [portaSnmp, setPortaSnmp] = useState(161);
  const [tipoSnmp, setTipoSnmp] = useState(2);
  const [comunidade, setComunidade] = useState("");
  const [loading, setLoading] = useState(false);

  function validateIp(ip) {
    const parts = ip.split(".");

    if (ip === "") {
      return "";
    } else {
      if (parts.length !== 4) {
        return "Formato inválido de endereço IP.";
      }
      for (let i = 0; i < 4; i++) {
        const part = parseInt(parts[i]);
        if (isNaN(part) || part < 0 || part > 255) {
          return "Formato inválido de endereço IP.";
        }
      }
      return "";
    }
  }

  const addHost = () => {
    let auxObj = { nome: "", ip: "" };
    auxObj = [...hosts, auxObj];
    setHosts(auxObj);
  };
  const removeHost = (index) => {
    const newHost = [...hosts];
    newHost.splice(index, 1);
    setHosts(newHost);
  };

  const handleHostsChange = (index, event) => {
    if (event.target.name && event.target.value) {
      const { name, value } = event.target;
      let newHosts = [...hosts];
      newHosts[index][name] = value;
      setHosts(newHosts);
    }
  };

  const handleHosts = () => {
    let arr = hosts.map((host) => {
      let nomeHost = host?.nome
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      nomeHost = `${nomeHost?.trim()} - ${host?.ip?.trim()}`;
      return {
        //nome: `${host?.nome?.trim()} - ${host.ip.trim()}`,
        nome: nomeHost,
        fabricante: fabricante.fabricante?.toUpperCase().trim(),
        funcao: funcao.funcao?.toUpperCase().trim(),
        modelo: modelo,
        coletor: coletor,
        userSsh,
        portaSsh: parseInt(portaSsh),
        senhaSsh,
        userTelnet,
        portaTelnet: parseInt(portaTelnet),
        senhaTelnet,
        interfaces: [
          {
            ip: host.ip,
            portaSnmp: parseInt(portaSnmp),
            tipoSnmp: parseInt(tipoSnmp),
            comunidade,
          },
        ],
      };
    });

    return arr;
  };

  const createHosts = async () => {
    try {
      setLoading(true);
      const dados = handleHosts();
      const response = await api.post("/easyAdd/createMany", { hosts: dados });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
        setLoading(false);
      } else {
        //  setAddEmMasa(false);
        setLoading(false);
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={stylesBulkAdd.container}>
        <Paper sx={stylesBulkAdd.paper}>
          <Stack
            gap={"12px"}
            direction={"column"}
            width={"100%"}
            justifyContent="space-between"
          >
            <Stack gap={"16px"} direction={"row"} width={"100%"}>
              <Autocomplete
                value={fabricante}
                onChange={(event, newInputValue) => {
                  setFabricante(newInputValue);
                  setFuncao([]);
                  setModelo([]);
                }}
                freeSolo
                options={predefinicoes.map((options) => options)}
                getOptionLabel={(options) => options.fabricante || ""}
                sx={stylesBulkAdd.textField}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Fabricante"
                      variant="outlined"
                      required
                    />
                    <Fade in={loadingPredefinicoes}>
                      <LinearProgress />
                    </Fade>
                  </>
                )}
              />
              <Autocomplete
                disabled={!fabricante?.fabricante}
                value={funcao}
                onChange={(event, newInputValue) => {
                  setFuncao(newInputValue);
                  setModelo([]);
                }}
                freeSolo
                options={fabricante?.Funcoes?.map((options) => options)}
                getOptionLabel={(options) => options.funcao || ""}
                sx={stylesBulkAdd.textField}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Funções"
                      variant="outlined"
                      required
                    />
                    <Fade in={loadingPredefinicoes}>
                      <LinearProgress />
                    </Fade>
                  </>
                )}
              />
              <Autocomplete
                disabled={!funcao?.funcao}
                value={modelo}
                onChange={(event, newInputValue) => {
                  setModelo(newInputValue);
                }}
                freeSolo
                options={funcao?.Modelos?.map((options) => options)}
                getOptionLabel={(options) => options.modelo || ""}
                sx={stylesBulkAdd.textField}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Modelo"
                      variant="outlined"
                      required
                    />
                    <Fade in={loadingPredefinicoes}>
                      <LinearProgress />
                    </Fade>
                  </>
                )}
              />
            </Stack>

            <Typography color={"primary"} sx={stylesBulkAdd.titles}>
              Monitorado por proxy
            </Typography>

            <Stack>
              <FormControl variant="outlined" label="Coletor" required>
                <InputLabel> Coletor</InputLabel>
                <Select
                  //fullWidth
                  value={coletor}
                  label="Coletor"
                  onChange={(event) => {
                    setColetor(event.target.value);
                  }}
                  sx={stylesBulkAdd.filter}
                >
                  <MenuItem value={"ZABBIX SERVER"}>ZABBIX SERVER</MenuItem>;
                  {Array.isArray(proxys) &&
                    proxys.map((p, index) => {
                      return <MenuItem value={p.proxyid}>{p.host}</MenuItem>;
                    })}
                </Select>
              </FormControl>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                gap={"12px"}
              >
                <Stack direction={"column"} gap={"12px"}>
                  <Typography
                    color={"primary"}
                    mt={2}
                    sx={stylesBulkAdd.titles}
                  >
                    Acesso SSH
                  </Typography>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack gap={"16px"} direction={"row"}>
                      <TextField
                        id="host-user"
                        variant="outlined"
                        label="USER SSH"
                        value={userSsh}
                        onChange={(event) => {
                          setUserSsh(event.target.value);
                        }}
                        // sx={stylesBulkAdd.textField}
                      />
                      <TextField
                        id="host-portaAcesso"
                        variant="outlined"
                        label="Porta SSH"
                        type="number"
                        value={portaSsh}
                        onChange={(event) => {
                          setPortaSsh(event.target.value);
                        }}
                        // sx={stylesBulkAdd.textField}
                      />
                      <TextField
                        id="host-senha"
                        variant="outlined"
                        label="Senha SSH"
                        value={senhaSsh}
                        onChange={(event) => {
                          setSenhaSsh(event.target.value);
                        }}
                        // sx={stylesBulkAdd.textField}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction={"column"} gap={"12px"}>
                  <Typography color={"primary"} fontWeight={500} mt={2}>
                    Acesso TELNET
                  </Typography>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack gap={"16px"} direction={"row"}>
                      <TextField
                        id="host-user"
                        variant="outlined"
                        label="USER TELNET"
                        value={userTelnet}
                        onChange={(event) => {
                          setUserTelnet(event.target.value);
                        }}
                        // sx={stylesBulkAdd.textField}
                      />
                      <TextField
                        id="host-portaAcesso"
                        variant="outlined"
                        label="Porta TELNET"
                        type="number"
                        value={portaTelnet}
                        onChange={(event) => {
                          setPortaTelnet(event.target.value);
                        }}
                        // sx={stylesBulkAdd.textField}
                      />
                      <TextField
                        id="host-senha"
                        variant="outlined"
                        label="Senha TELNET"
                        value={senhaTelnet}
                        onChange={(event) => {
                          setSenhaTelnet(event.target.value);
                        }}
                        //sx={stylesBulkAdd.textField}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography color={"primary"} sx={stylesBulkAdd.titles}>
                Opções Gerais de Interface
              </Typography>
            </Stack>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="host-porta"
                name="portaSnmp"
                variant="outlined"
                label="Porta SNMP"
                type="number"
                value={portaSnmp}
                onChange={(event) => {
                  setPortaSnmp(event.target.value);
                }}
                required
                sx={stylesBulkAdd.porta}
              />
              <FormControl variant="outlined" sx={stylesBulkAdd.porta}>
                <InputLabel shrink> Tipo SNMP</InputLabel>
                <Select
                  input={""}
                  //fullWidth
                  name="tipoSnmp"
                  label="Tipo SNMP"
                  value={tipoSnmp}
                  onChange={(event) => {
                    setTipoSnmp(event.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>V1</MenuItem>
                  <MenuItem value={2}>V2</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="comunidade-hosts"
                sx={stylesBulkAdd.comunidade}
                variant="outlined"
                label="Comunidade"
                name="comunidade"
                required
                value={comunidade}
                onChange={(event) => {
                  setComunidade(event.target.value);
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography color={"primary"} sx={stylesBulkAdd.titles}>
                Opções Individuais de Host
              </Typography>
              <IconButton size="small" onClick={addHost}>
                <Add color="primary" />
              </IconButton>
            </Stack>
            {hosts.map((host, index) => (
              <Stack gap={"16px"} direction={"row"} key={index}>
                <TextField
                  id="nome-host"
                  variant="outlined"
                  label="Nome"
                  name="nome"
                  value={host.nome}
                  onChange={(event) => {
                    handleHostsChange(index, event);
                  }}
                  required
                  sx={stylesBulkAdd.textField}
                />{" "}
                <TextField
                  id="ip"
                  name="ip"
                  variant="outlined"
                  label="IP"
                  value={host.ip}
                  onChange={(event) => {
                    handleHostsChange(index, event);
                  }}
                  onBlur={() => setError(validateIp(host.ip))}
                  error={Boolean(error)}
                  helperText={error}
                  required
                  //sx={stylesBulkAdd.textField}
                />{" "}
                {hosts.length > 1 && (
                  <IconButton onClick={() => removeHost(index)}>
                    <Delete color="secondary" />
                  </IconButton>
                )}
              </Stack>
            ))}
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => {
                  createHosts();
                }}
              >
                Salvar
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Box>
    </>
  );
}
