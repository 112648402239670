import { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
  Avatar,
  Badge,
  IconButton,
  Tooltip,
  Alert,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Collapse,
  createSvgIcon,
} from "@mui/material";
import { FilterList, Edit } from "@mui/icons-material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../services/api";
import apiSistema from "../../services/apiSistema";
import Chat from "./chat";
import ModalDemanda from "./modalNovaDemanda";
import ModalEditaDemanda from "./modalEditaDemanda";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
    alignItems: "center",
    ml: "20px ",
  },
  paper: {
    width: "98.5%",
    minWidth: "900px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
  },
  campoFiltro: {
    width: "200px",
  },
  button: { height: "55px", minWidth: "120px" },
  TypographyCampo: { fontSize: 15 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
};
const CommentIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-1ticslg"
  >
    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
  </svg>
);

export default function Suporte(props) {
  //dados linhas
  const { socket } = props;
  const [responsavel, setResponsavel] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [status, setStatus] = useState("");
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [data, setData] = useState(new Date());
  const [dataChange, setDataChange] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  //filtro
  const [hiddenFilter, setHiddenFilter] = useState(false);
  //Datagrid
  const [rows, setRows] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [newRow, setNewRow] = useState("");
  const [updateRow, setUpdateRow] = useState("");
  const [maxCharLength, setMaxCharLength] = useState(40);
  const navigate = useNavigate();
  useEffect(() => {
    if (newRow && rows) {
      setRows([newRow, ...rows]);
    }
  }, [newRow]);

  useEffect(() => {
    if (updateRow) {
      const novoArray = rows.map((row) => {
        if (row.id === updateRow.id) {
          return { ...updateRow };
        } else {
          return row;
        }
      });
      setRows(novoArray);
    }
  }, [updateRow]);
  useEffect(() => {
    //funcao para chamar a api de relatoriosCliente
    handleApiGetRelatorios();
    // funcao para atualizar a variavel maxCharLength com base na largura do DataGrid
    const updateMaxCharLength = () => {
      //obtem a largura atual do DataGrid
      const dataGridWidth =
        document.querySelector(".MuiDataGrid-root")?.clientWidth;
      //ajusta a variavel maxCharLength com base na largura do DataGrid
      if (dataGridWidth >= 600) {
        setMaxCharLength(60);
      } else {
        setMaxCharLength(40);
      }
    };
    updateMaxCharLength();
    window.addEventListener("resize", updateMaxCharLength);
    //limpa o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener("resize", updateMaxCharLength);
    };
  }, []);

  //funcao para processar os valores retornados pela api para o formato utilizado pela pagina
  function handleRows(params) {
    const pushRows = params.map((dataRow) => {
      let newObj = {};
      newObj.id = dataRow.id;
      //newObj.responsavel = dataRow.perfil ? dataRow.perfil : "";
      newObj.responsavel = dataRow.usuario ? dataRow.usuario.Perfil : "";
      newObj.solicitante = dataRow.usuarioCliente
        ? dataRow.usuarioCliente.PerfilCliente
        : "";
      newObj.codigo = dataRow.codigo;
      newObj.descricao = dataRow.descricao;
      newObj.categoria = dataRow.categoria;
      newObj.subCategoria = dataRow.subCategoria;
      newObj.status = dataRow.status;
      newObj.previsao = dataRow.previsao;
      newObj.data = new Date(new Date(dataRow.createAt) - 3 * 60 * 60 * 1000); //coreção de fuso horario, com diferença de 3 horas
      newObj.topicos = dataRow._count.TopicoRelatorio;
      return newObj;
    });
    setRows(pushRows);
  }

  //funcao para copiar texto para area de transferencia
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "campo copiado!",
      severity: "info",
    });
  };

  //funcao para filtrar linhas da tabela
  function filteredRows(rows) {
    const auxRows = rows.filter((row, index) => {
      let auxFiltroSolicitante = false;
      if (row.solicitante.pessoaFisica && row.solicitante) {
        auxFiltroSolicitante = row.solicitante.nome
          ?.toLowerCase()
          .includes(solicitante?.toLowerCase());
      } else {
        auxFiltroSolicitante = row.solicitante
          ? row.solicitante.nomeFantasia
              ?.toLowerCase()
              .includes(solicitante.toLowerCase())
          : true;
      }
      if (!row.solicitante && solicitante) {
        auxFiltroSolicitante = false;
      }

      const filtroSolicitante = auxFiltroSolicitante;

      let aux = false;
      aux = row.responsavel
        ? row.responsavel.nome
            ?.toLowerCase()
            .includes(responsavel.toLowerCase())
        : true;
      if (!row.responsavel && responsavel) {
        aux = false;
      }
      const filtroResponsavel = aux;
      if (dataChange) {
        return (
          filtroResponsavel &&
          filtroSolicitante &&
          row.descricao?.toLowerCase().includes(descricao?.toLowerCase()) &&
          row.status?.toString().includes(status?.toString()) &&
          row.codigo?.toLowerCase().includes(codigo?.toLowerCase()) &&
          new Date(row.data)
            .toLocaleDateString()
            .includes(new Date(data).toLocaleDateString())
        );
      } else {
        return (
          filtroResponsavel &&
          filtroSolicitante &&
          row.descricao?.toLowerCase().includes(descricao?.toLowerCase()) &&
          row.status?.toString().includes(status?.toString()) &&
          row.codigo?.toLowerCase().includes(codigo?.toLowerCase())
        );
      }
    });
    return auxRows;
  }

  //funcao para retornar apenas as demandas pendentes
  function rowsPendant(rows) {
    const filterPerPendant = rows.filter((row, index) => {
      return (
        row.status === 0 ||
        row.status === 1 ||
        row.status === 3 ||
        row.status === 4
      );
    });
    return filterPerPendant;
  }
  //funcao para retornar apenas as demandas finalizadas
  function rowsComplete(rows) {
    const filterPerComplete = rows.filter((row, index) => {
      return row.status === 2;
    });
    return filterPerComplete;
  }
  function rowsArquive(rows) {
    const filterPerArquive = rows.filter((row, index) => {
      return row.status === 5;
    });
    return filterPerArquive;
  }
  function atualizaBadgeTopicos(somaTopico) {
    const novoArray = rows.map((row) => {
      if (row.id === selectedRow.id) {
        // newObj = {};
        let newObj = row;
        newObj.topicos =
          row.topicos + somaTopico >= 0 ? row.topicos + somaTopico : 0;
        return newObj;
      }
      return row;
    });
    setRows(novoArray);
  }
  /* async function handleApiGetRelatorios() {
    try {
      setLoadingPage(true);
      const response = await api.get("/suporte/findMany");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível buscar dados",
          severity: "error",
        });
        setLoadingPage(false);
      } else {
        setLoadingPage(false);
        if (response.data) {
          handleRows(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingPage(false);
    }
  }*/
  async function handleApiGetRelatorios() {
    try {
      setLoadingPage(true);
      const response = await apiSistema.get("/suporte/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível buscar dados",
          severity: "error",
        });
        setLoadingPage(false);
      } else {
        setLoadingPage(false);
        if (response.data) {
          handleRows(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingPage(false);
    }
  }
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 1,
      renderCell: (params) => {
        const text = params.value || "";
        return (
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text.length > maxCharLength
              ? `${text.substring(0, maxCharLength)}...`
              : text}
          </Box>
        );
      },
    },
    {
      field: "codigo",
      headerName: "Código",
      width: 140,
      renderCell: (params) => (
        <>
          {params.value && (
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(params.value);
                }}
              >
                {params.value}
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "responsavel",
      headerName: "Responsável",
      width: 180,
      sortable: false,
      //  flex: 0.5,
      renderCell: (params) => {
        let nomeCompleto = "";
        let names = "";
        if (params.value?.nome) {
          nomeCompleto = params.value.sobrenome
            ? params.value.nome?.trim() + " " + params.value.sobrenome?.trim()
            : params.value.nome?.trim() + "";
          names = nomeCompleto.split(" "); // params.value.split(" ") ;
        }
        return (
          <>
            {params.value.nome ? (
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Avatar
                  sx={{ bgcolor: "orangered", width: "30px", height: "30px" }}
                  //alt={names[0].toUpperCase()}
                  src={params.value.foto}
                >
                  {names[0][0]?.toUpperCase()}
                </Avatar>
                <Tooltip title={nomeCompleto} placement="bottom-end">
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      lineHeight: 1.5,
                      fontWeight: 400,
                      width: "140px",
                      textTransform: "capitalize",
                    }}
                  >
                    {names.length <= 1
                      ? params.value
                      : `${names[0]} ${names[1][0]}.`}
                  </Typography>
                </Tooltip>
              </Stack>
            ) : (
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: 1.5,
                  fontWeight: 400,
                  width: "140px",
                  textTransform: "capitalize",
                }}
              >
                Indefinido
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "solicitante",
      headerName: "Solicitante",
      width: 180,
      sortable: false,
      renderCell: (params) => {
        let names = "";
        let nomeCompleto = "";
        if (params.value) {
          if (params.value.pessoaFisica) {
            nomeCompleto = params.value.sobrenome
              ? params.value.nome?.trim() + " " + params.value.sobrenome?.trim()
              : params.value.nome?.trim();
            names = nomeCompleto.split(" ").filter(Boolean);
          } else {
            nomeCompleto = params.value.nomeFantasia?.trim();
            names = nomeCompleto.split(" ").filter(Boolean);
          }
        }
        return (
          <>
            {names ? (
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Avatar
                  sx={{ bgcolor: "green", width: "30px", height: "30px" }}
                  //alt={names[0].toUpperCase()}
                  src={params.value.foto}
                >
                  {names[0][0].toUpperCase()}
                </Avatar>
                <Tooltip title={nomeCompleto} placement="bottom-end">
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      lineHeight: 1.5,
                      fontWeight: 400,
                      width: "140px",
                      textTransform: "capitalize",
                    }}
                  >
                    {names.length <= 1
                      ? `${names[0]}`
                      : `${names[0]} ${names[1][0]}.`}
                  </Typography>
                </Tooltip>
              </Stack>
            ) : (
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: 1.5,
                  fontWeight: 400,
                  width: "140px",
                  textTransform: "capitalize",
                }}
              >
                Indefinido
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {params.value === 0 ? (
            <>
              <Chip
                label={"Pendente"}
                sx={{
                  bgcolor: "chip.pending",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 1 ? (
            <>
              <Chip
                label={"Em andamento"}
                sx={{
                  bgcolor: "chip.inProgress",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 2 ? (
            <>
              <Chip
                label={"Concluida"}
                sx={{
                  bgcolor: "chip.completed",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 3 ? (
            <>
              <Chip
                label={"Aguardando revisão"}
                sx={{
                  bgcolor: "chip.waitingReview",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 4 ? (
            <>
              <Chip
                label={"Aguardando retorno"}
                sx={{
                  bgcolor: "chip.waitingReturn",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : params.value === 5 ? (
            <>
              <Chip
                label={"Arquivada"}
                sx={{
                  bgcolor: "chip.arquive",
                  color: "white",
                  width: "150px",
                }}
              />
            </>
          ) : null}
        </Box>
      ),
    },
    {
      field: "categoria",
      headerName: "Categoria",
      width: 110,
    },
    {
      field: "subCategoria",
      headerName: "Subcategoria",
      width: 180,
      renderCell: (params) => {
        const maxCharLength = 20; // Defina o comprimento máximo do texto antes de exibir os pontos de suspensão
        const text = params.value || "";
        return (
          <Tooltip title={params.value} placement="bottom-end">
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {text.length > maxCharLength
                ? `${text.substring(0, maxCharLength)}...`
                : text}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "data",
      headerName: "Data ",
      type: "date",
      width: 120,
      renderCell: (params) => (
        <>
          {params.value && (
            <Typography variant="subtitle1" sx={styles.TypographyCampo}>
              {new Date(params.value).toLocaleDateString()}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "previsao",
      headerName: "Previsão",
      type: "date",
      width: 120,
      renderCell: (params) => (
        <>
          {params.value && (
            <Typography variant="subtitle1" sx={styles.TypographyCampo}>
              {new Date(params.value).toLocaleDateString()}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "topicos",
      headerName: "",
      sortable: false,
      width: 60,
      // editable: true,
      renderCell: (params) => {
        const onClick = (e) => {
          //setOpenChat(true);
          e.stopPropagation();

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          navigate(`/suporte/${thisRow.id}`);
          hanldeUpDateSelectRow(thisRow);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        return (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            width={60}
            sx={{ mt: 0.4 }}
          >
            <IconButton aria-label="topicos" onClick={onClick}>
              <Badge badgeContent={params.value} color="primary">
                {" "}
                <Tooltip title="Tópicos">
                  <CommentIcon />
                </Tooltip>
              </Badge>
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 40,

      renderCell: (params) => {
        const onClick = (e) => {
          //e.stopPropagation();
          // don't select this row after clicking
          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper} elevation={3}>
        {" "}
        <Typography
          variant="h6"
          component={"div"}
          gutterBottom
          sx={{
            fontSize: "1.25rem",
            fontWeight: "500",
          }}
          color="primary"
        >
          Tarefas Abertas
        </Typography>{" "}
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Collapse orientation="horizontal" in={!hiddenFilter}>
              <Stack
                direction={"row"}
                gap={"20px"}
                flexWrap={!hiddenFilter ? "wrap" : "nowrap"}
                minWidth={"650px"}
              >
                <TextField
                  id="filtro-responsavel"
                  variant="outlined"
                  label="Responsável"
                  value={responsavel}
                  sx={styles.campoFiltro}
                  onChange={(event) => {
                    setResponsavel(event.target.value);
                  }}
                  type="text"
                />{" "}
                <TextField
                  id="filtro-solicitante"
                  variant="outlined"
                  label="Solicitante"
                  value={solicitante}
                  sx={styles.campoFiltro}
                  onChange={(event) => {
                    setSolicitante(event.target.value);
                  }}
                  type="text"
                />{" "}
                <TextField
                  id="filtro-descricao"
                  variant="outlined"
                  label="Descricao"
                  value={descricao}
                  sx={styles.campoFiltro}
                  onChange={(event) => {
                    setDescricao(event.target.value);
                  }}
                  type="text"
                />
                <TextField
                  id="filtro-codigo"
                  variant="outlined"
                  label="Código"
                  value={codigo}
                  sx={styles.campoFiltro}
                  onChange={(event) => {
                    setCodigo(event.target.value);
                  }}
                  type="text"
                />
                <FormControl>
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    labelId="select-status"
                    id="filtro-status"
                    value={status}
                    sx={styles.campoFiltro}
                    label="Status"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={""}>Indefinido</MenuItem>
                    <MenuItem value={0}>Pendente</MenuItem>
                    <MenuItem value={1}>Em andamento</MenuItem>
                    <MenuItem value={2}>Concluída</MenuItem>
                    <MenuItem value={3}>Aguardando Revisão</MenuItem>
                    <MenuItem value={4}>Aguardando Retorno</MenuItem>
                  </Select>
                </FormControl>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    value={data}
                    id="data"
                    label="Data de Criação"
                    onChange={(newValeu) => {
                      if (newValeu) {
                        setData(newValeu);
                        setDataChange(true);
                      } else {
                        setData(new Date());
                        setDataChange(false);
                      }
                    }}
                    componentsProps={{
                      actionBar: {
                        // The actions will be the same between desktop and mobile
                        actions: ["clear"],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={styles.campoFiltro}
                        color="primary"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>{" "}
              </Stack>{" "}
            </Collapse>
            <Box sx={{ mt: 1 }}>
              <Tooltip
                title={"Ocultar filtros"}
                onClick={() => {
                  setHiddenFilter(!hiddenFilter);
                }}
              >
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>{" "}
          <Button
            variant="contained"
            sx={styles.button}
            onClick={() => {
              setOpenCreateModal(true);
            }}
          >
            CRIAR DEMANDA
          </Button>
        </Stack>{" "}
        <DataGrid
          sx={{
            fontSize: 15,
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
          getRowHeight={() => "auto"}
          autoHeight={true}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={filteredRows(rowsPendant(rows))}
          columns={columns}
          rowsPerPageOptions={[10, 50, 100]}
          initialState={{
            ...rows.initialState,
            pagination: {
              pageSize: 10,
            },
          }}
          loading={loadingPage}
          disableColumnMenu
          disableRowSelectionOnClick={true}
        />{" "}
      </Paper>

      <Paper sx={styles.paper} elevation={3}>
        <Typography
          variant="h6"
          component={"div"}
          gutterBottom
          sx={{
            fontSize: "1.25rem",
            fontWeight: "500",
          }}
          color="darkgreen"
        >
          Tarefas Fechadas
        </Typography>
        <DataGrid
          sx={{
            fontSize: 15,
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
          getRowHeight={() => "auto"}
          autoHeight={true}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={filteredRows(rowsComplete(rows))}
          columns={columns}
          rowsPerPageOptions={[10, 50, 100]}
          initialState={{
            ...rows.initialState,
            pagination: {
              pageSize: 10,
            },
          }}
          loading={loadingPage}
          disableColumnMenu
          disableRowSelectionOnClick={true}
        />{" "}
      </Paper>
      <Paper sx={styles.paper}>
        <Typography
          variant="h6"
          component={"div"}
          gutterBottom
          sx={{
            fontSize: "1.25rem",
            fontWeight: "500",
          }}
          color="info"
        >
          Tarefas Arquivadas
        </Typography>
        <DataGrid
          sx={{
            fontSize: 15,
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
          getRowHeight={() => "auto"}
          autoHeight={true}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={filteredRows(rowsArquive(rows))}
          columns={columns}
          rowsPerPageOptions={[10, 50, 100]}
          initialState={{
            ...rows.initialState,
            pagination: {
              pageSize: 10,
            },
          }}
          loading={loadingPage}
          disableColumnMenu
          disableRowSelectionOnClick={true}
        />{" "}
      </Paper>
      <ModalDemanda
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        newRow={newRow}
        setNewRow={setNewRow}
      />
      <ModalEditaDemanda
        selectedRow={selectedRow}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        setUpdateRow={setUpdateRow}
      />
      <Chat
        selectedRow={selectedRow}
        atualizaBadgeTopicos={atualizaBadgeTopicos}
        CommentIcon={CommentIcon}
        socket={socket}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
