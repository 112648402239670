import {
  Paper,
  Typography,
  Stack,
  Box,
  useTheme,
  Tooltip,
  Button,
} from "@mui/material";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Dangerous,
  Error,
  LocalFireDepartment,
  ReportProblem,
} from "@mui/icons-material";

export default function CardAlerts(props) {
  const { alert, setFocusNodeId } = props;
  const theme = useTheme();
  const [typeCard, setTypeCard] = useState({});

  useEffect(() => {
    function type() {
      return alert?.severity == 2
        ? {
            cor: theme.palette.color.zabbixSeverty.warning,
            icon: (
              <ReportProblem
                sx={{ color: theme.palette.color.zabbixSeverty.warning }}
              />
            ),
            title: "Alerta",
          }
        : alert?.severity == 3
        ? {
            cor: theme.palette.color.zabbixSeverty.average,
            icon: (
              <Error
                sx={{ color: theme.palette.color.zabbixSeverty.average }}
              />
            ),
            title: "Alerta Moderado",
          }
        : alert?.severity == 4
        ? {
            cor: theme.palette.color.zabbixSeverty.high,
            icon: (
              <Dangerous
                sx={{ color: theme.palette.color.zabbixSeverty.high }}
              />
            ),
            title: "Alerta de alto impacto",
          }
        : {
            cor: theme.palette.color.zabbixSeverty.disaster,
            icon: (
              <LocalFireDepartment
                sx={{ color: theme.palette.color.zabbixSeverty.disaster }}
              />
            ),
            title: "Alerta de Desastre",
          };
    }
    setTypeCard(type());
  }, [alert]);

  const styles = {
    title: {
      fontSize: "0.875em",
      maxWidth: "220px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "left",
    },
    message: {
      fontSize: "0.75em",
      maxWidth: "220px",
      textAlign: "left",
      WebkitLineClamp: 2,
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      color: theme.palette.color.textDisabled,

      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    line: {
      backgroundColor: typeCard.cor,
      height: "100%",
      width: "4px",
    },
    card: {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      boxShadow: "0px 0px 5px  #0000001a",
      position: "relative",
      width: "100%",
      padding: "15px 10px",
      display: "flex",
      direction: "row",
      height: "90px",
      alignItems: "center",
      gap: 2,
      "&:hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  };

  return (
    <>
      <Typography>
        {dayjs(new Date(alert?.clock * 1000)).format("DD/MM/YYYY HH:mm")}
      </Typography>
      <Tooltip title={alert?.name} placement="left">
        <Button
          onClick={() => setFocusNodeId(alert?.elementId)}
          sx={{
            padding: "0px",
          }}
        >
          <Paper elevation={0} sx={styles.card}>
            <Box sx={styles.line} />
            <Stack direction={"column"} alignItems="start">
              <Typography sx={styles.title}>
                {typeCard?.title?.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  ...styles.message,
                }}
              >
                {alert?.name}
              </Typography>
            </Stack>
            <Stack sx={{ position: "absolute", top: 15, right: 10 }}>
              {typeCard.icon}
            </Stack>
          </Paper>
        </Button>
      </Tooltip>
    </>
  );
}
