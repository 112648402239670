import {
  Box,
  Paper,
  Stack,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";

import { Add, Search } from "@mui/icons-material";

import MyMap from "./myMaps";

import CreateScreen from "./createScreen";

import React, { useEffect, useMemo, useState } from "react";
import api from "../../../services/api";
import LoadingPage from "../../../componentes/loadingPage";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: 2,
  },
  stack: { width: "100%" },
  headerPaper: {
    padding: "16px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "calc(100% - 60px)",
    //width: "calc(100% - 60px)",
    borderRadius: 3,
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  headerTypography: {
    fontWeight: 600,
    color: "primary.main",
  },

  addIcon: { mt: -0.4 },
};

export default function MapasDelinha(props) {
  const [createScreen, setCreateScreen] = useState(false);
  const [mapas, setMapas] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    handleApiGetMapas();
  }, []);

  //rota de puxar mapa
  async function handleApiGetMapas() {
    try {
      const response = await api.get("/mapas/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival buscar mapas",
          severity: "error",
        });
      } else {
        setMapas(response.data);
      }
      setLoadingPage(true);
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
    }
  }

  function addMapa(newMapa) {
    const novoArray = mapas;
    novoArray.push(newMapa);
    setMapas(novoArray);
  }

  function updateMapa(novoMapa) {
    const novoArray = mapas.map((obj) => {
      if (obj.id === novoMapa.id) {
        return novoMapa;
      } else {
        return obj;
      }
    });
    setMapas(novoArray);
  }

  function deleteMapa(id) {
    const novoArray = mapas.filter((objeto) => objeto.id !== id);
    setMapas(novoArray);
  }

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.headerPaper} elevation={3}>
        {!createScreen && (
          <Box sx={styles.headerBox}>
            <Typography variant="h6" gutterBottom sx={styles.headerTypography}>
              Mapas de backbone
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={styles.stack}
            >
              <TextField
                label={"Titulo do mapa"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                onClick={() => {
                  setCreateScreen(true);
                }}
                sx={
                  {
                    // height: "2.5rem"
                  }
                }
                endIcon={
                  <>
                    <Add sx={styles.addIcon} />
                  </>
                }
              >
                Novo mapa
              </Button>
            </Stack>
          </Box>
        )}
        {createScreen && (
          <>
            <CreateScreen
              createScreen={createScreen}
              setCreateScreen={setCreateScreen}
              addMapa={addMapa}
            />
          </>
        )}
      </Paper>
      {!loadingPage && <LoadingPage mt={"10%"} />}{" "}
      {loadingPage && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {Array.isArray(mapas) &&
            mapas.map((mapa, index) => {
              return (
                <MyMap
                  mapa={mapa}
                  key={mapa.id}
                  updateMapa={updateMapa}
                  deleteMapa={deleteMapa}
                />
              );
            })}
        </Box>
      )}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
