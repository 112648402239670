import {
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Modal,
  Paper,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
const styles = {
  filter: { width: "300px" },
  textField: { width: "180px" },
  comunidade: { width: "340px" },
  porta: { width: "100px" },
  titles: { fontWeight: 500, mt: 2 },
};

export default function ModalCreateHost(props) {
  const {
    openCreateModal,
    setOpenCreateModal,
    setSnackbar,
    AddRow,
    predefinicoes,
    proxys,
    handleApiGetHosts,
  } = props;
  const [nome, setNome] = useState("");
  const [fabricante, setFabricante] = useState([]);
  const [coletor, setColetor] = useState("");
  const [funcao, setFuncao] = useState([]);
  const [userSsh, setUserSsh] = useState("");
  const [portaSsh, setPortaSsh] = useState(22);
  const [senhaSsh, setSenhaSsh] = useState("");
  const [userTelnet, setUserTelnet] = useState("");
  const [portaTelnet, setPortaTelnet] = useState(23);
  const [senhaTelnet, setSenhaTelnet] = useState("");
  const [modelo, setModelo] = useState("");
  const [interfaces, setInterfaces] = useState([
    { ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "" },
  ]);
  const [error, setError] = useState("");
  const [loadingConfirmar, setLoadingConfirmar] = useState(true);
  //const [value, setValue] = useState(null);
  //const [proxy, setProxy] = useState("");

  const handleInterfaceChange = (index, event) => {
    const { name, value } = event.target;
    const newInterfaces = [...interfaces];
    newInterfaces[index][name] = value;
    setInterfaces(newInterfaces);
  };

  const addInterface = () => {
    if (interfaces.length < 2) {
      setInterfaces([
        ...interfaces,
        { ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "" },
      ]);
    }
  };

  const removeInterface = (index) => {
    const newInterfaces = [...interfaces];
    newInterfaces.splice(index, 1);
    setInterfaces(newInterfaces);
  };

  const handleHosts = () => {
    let nomeHost = nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    nomeHost = `${nomeHost?.trim()} - ${interfaces[0].ip.trim()}`;
    let arr = [
      {
        nome: nomeHost,
        fabricante: fabricante?.fabricante?.toUpperCase().trim(),
        funcao: funcao?.funcao?.toUpperCase().trim(),
        modelo: modelo,
        coletor: coletor,
        userSsh,
        portaSsh: parseInt(portaSsh),
        senhaSsh,
        userTelnet,
        portaTelnet: parseInt(portaTelnet),
        senhaTelnet,
        interfaces,
      },
    ];
    return arr;
  };

  const createHosts = async () => {
    try {
      setLoadingConfirmar(true);
      const dados = handleHosts();
      const response = await api.post("/easyAdd/createMany", { hosts: dados });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possível salvar dados",
          severity: "error",
        });
        setLoadingConfirmar(false);
      } else {
        setLoadingConfirmar(false);
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
        clearModal();
        setOpenCreateModal(false);
        handleApiGetHosts();
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingConfirmar(false);
      console.error(error);
    } finally {
      setLoadingConfirmar(false);
    }
  };
  useEffect(() => {
    if (
      nome !== "" &&
      interfaces[0]?.ip &&
      interfaces[0]?.portaSnmp &&
      interfaces[0]?.tipoSnmp &&
      interfaces[0]?.comunidade &&
      fabricante !== "" &&
      coletor !== "" &&
      funcao !== "" &&
      modelo !== ""
    ) {
      setLoadingConfirmar(false);
    } else {
      setLoadingConfirmar(true);
    }
  }, [nome, fabricante, coletor, funcao, modelo, interfaces]);

  function validateIp(ip) {
    const parts = ip.split(".");

    if (ip === "") {
      return "";
    } else {
      if (parts.length !== 4) {
        return "Formato inválido de endereço IP.";
      }
      for (let i = 0; i < 4; i++) {
        const part = parseInt(parts[i]);
        if (isNaN(part) || part < 0 || part > 255) {
          return "Formato inválido de endereço IP.";
        }
      }
      return "";
    }
  }

  function clearModal() {
    setNome("");
    setFabricante(null);
    setModelo("");
    setColetor("");
    setFuncao("");
    setUserSsh("");
    setPortaSsh(22);
    setSenhaSsh("");
    setUserTelnet("");
    setPortaTelnet(23);
    setSenhaTelnet("");
    setInterfaces([{ ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "" }]);
  }
  async function handleApi() {
    try {
      setLoadingConfirmar(true);
      const response = await api.post("/easyAdd/create", {
        nome,
        fabricante: fabricante?.fabricante,
        funcao: funcao?.funcao,
        modelo: modelo,
        coletor,
        userSsh,
        portaSsh,
        senhaSsh,
        userTelnet,
        portaTelnet,
        senhaTelnet,
        interfaces,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });

        setLoadingConfirmar(false);
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
        setLoadingConfirmar(false);
        clearModal();
        setOpenCreateModal(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
      setLoadingConfirmar(false);
    }
  }
  return (
    <Modal
      open={openCreateModal}
      onClose={() => {
        setOpenCreateModal(false);
      }}
      sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
    >
      <Paper sx={{ borderRadius: 3, boxShadow: 24 }}>
        <DialogTitle color="primary">Preencha os dados</DialogTitle>
        <DialogContent>
          <Stack gap={"12px"} direction={"column"}>
            <Stack gap={"16px"} sx={{ mt: 1 }} direction={"row"}>
              <TextField
                id="nome-host"
                variant="outlined"
                label="Nome"
                value={nome}
                onChange={(event) => {
                  setNome(event.target.value);
                }}
                required
                sx={styles.textField}
              />
              <Autocomplete
                value={fabricante}
                onChange={(event, newInputValue) => {
                  setFabricante(newInputValue);
                  setFuncao([]);
                  setModelo([]);
                }}
                freeSolo
                options={predefinicoes.map((options) => options)}
                getOptionLabel={(options) => options.fabricante || ""}
                sx={styles.textField}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fabricante"
                    variant="outlined"
                    required
                  />
                )}
              />
              <Autocomplete
                disabled={!fabricante?.fabricante}
                value={funcao}
                onChange={(event, newInputValue) => {
                  setFuncao(newInputValue);
                  setModelo([]);
                }}
                freeSolo
                options={fabricante?.Funcoes?.map((options) => options)}
                getOptionLabel={(options) => options.funcao || ""}
                sx={styles.textField}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Funções"
                    variant="outlined"
                    required
                  />
                )}
              />
              <Autocomplete
                disabled={!funcao?.funcao}
                value={modelo}
                onChange={(event, newInputValue) => {
                  setModelo(newInputValue);
                }}
                freeSolo
                options={funcao?.Modelos?.map((options) => options)}
                getOptionLabel={(options) => options.modelo || ""}
                sx={styles.textField}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Modelo"
                    variant="outlined"
                    required
                  />
                )}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography color={"primary"} sx={styles.titles}>
                Interfaces
              </Typography>
              {/*         <IconButton size="small" onClick={addInterface}>
                {interfaces.length >= 2 ? null : <Add color="primary" />}
              </IconButton> */}
            </Stack>

            {interfaces.map((value, index) => (
              <Stack key={index} gap={"16px"} direction={"row"}>
                <TextField
                  id="ip"
                  name="ip"
                  variant="outlined"
                  label="IP"
                  value={interfaces[index].ip}
                  onChange={(event) => {
                    handleInterfaceChange(index, event);
                  }}
                  onBlur={() => setError(validateIp(interfaces[0].ip))}
                  error={Boolean(error)}
                  helperText={error}
                  required
                  sx={styles.textField}
                />{" "}
                <TextField
                  id="host-porta"
                  name="portaSnmp"
                  variant="outlined"
                  label="Porta SNMP"
                  type="number"
                  value={interfaces[index].portaSnmp}
                  onChange={(event) => {
                    handleInterfaceChange(index, event);
                  }}
                  required
                  sx={styles.porta}
                />
                <FormControl variant="outlined" sx={styles.porta}>
                  <InputLabel shrink> Tipo SNMP</InputLabel>
                  <Select
                    fullWidth
                    name="tipoSnmp"
                    label="Tipo SNMP"
                    value={interfaces[index].tipoSnmp}
                    onChange={(event) => {
                      handleInterfaceChange(index, event);
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>V1</MenuItem>
                    <MenuItem value={2}>V2</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="comunidade-hosts"
                  sx={styles.comunidade}
                  variant="outlined"
                  label="Comunidade"
                  name="comunidade"
                  required
                  value={interfaces[index].comunidade}
                  onChange={(event) => {
                    handleInterfaceChange(index, event);
                  }}
                />
                {interfaces.length > 1 && (
                  <IconButton onClick={() => removeInterface(index)}>
                    <Delete color="secondary" />
                  </IconButton>
                )}
              </Stack>
            ))}

            <Typography color={"primary"} sx={styles.titles}>
              Monitorado por proxy
            </Typography>

            <FormControl variant="outlined" label="Coletor" required>
              <InputLabel> Coletor</InputLabel>
              <Select
                fullWidth
                value={coletor}
                label="Coletor"
                onChange={(event) => {
                  setColetor(event.target.value);
                }}
                sx={styles.filter}
              >
                {Array.isArray(proxys) &&
                  proxys.map((p, index) => {
                    return <MenuItem value={p.host}>{p.host}</MenuItem>;
                  })}
                <MenuItem value={""}>Sem proxy</MenuItem>
              </Select>
            </FormControl>
            <Typography
              color={"primary"}
              fontWeight={500}
              mt={2}
              sx={styles.titles}
            >
              Acesso SSH
            </Typography>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="host-user"
                variant="outlined"
                label="USER SSH"
                value={userSsh}
                onChange={(event) => {
                  setUserSsh(event.target.value);
                }}
                sx={styles.textField}
              />
              <TextField
                id="host-portaAcesso"
                variant="outlined"
                label="Porta SSH"
                type="number"
                value={portaSsh}
                onChange={(event) => {
                  setPortaSsh(event.target.value);
                }}
                sx={styles.textField}
              />
              <TextField
                id="host-senha"
                variant="outlined"
                label="Senha SSH"
                value={senhaSsh}
                onChange={(event) => {
                  setSenhaSsh(event.target.value);
                }}
                sx={styles.textField}
              />
            </Stack>
            <Typography color={"primary"} fontWeight={500} mt={2}>
              Acesso TELNET
            </Typography>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="host-user"
                variant="outlined"
                label="USER TELNET"
                value={userTelnet}
                onChange={(event) => {
                  setUserTelnet(event.target.value);
                }}
                sx={styles.textField}
              />
              <TextField
                id="host-portaAcesso"
                variant="outlined"
                label="Porta TELNET"
                type="number"
                value={portaTelnet}
                onChange={(event) => {
                  setPortaTelnet(event.target.value);
                }}
                sx={styles.textField}
              />
              <TextField
                id="host-senha"
                variant="outlined"
                label="Senha TELNET"
                value={senhaTelnet}
                onChange={(event) => {
                  setSenhaTelnet(event.target.value);
                }}
                sx={styles.textField}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenCreateModal(false);
              clearModal();
            }}
            color="secondary"
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            disabled={loadingConfirmar}
            onClick={() => {
              createHosts();
            }}
          >
            confirmar
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
