import { Add, Delete, Refresh } from "@mui/icons-material";
import {
  Stack,
  TextField,
  IconButton,
  Autocomplete,
  Fade,
  LinearProgress,
  Tooltip,
  FormControlLabel,
  Typography,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import api from "../../../../../services/api";
import { FormGroup } from "@mui/material";

const styles = {
  autcomplete: { mt: 1, width: "380px" },
  tooltipSpan: { mt: 10 },
};
export default function ConfigureRelatorio(props) {
  const {
    hosts,
    loadingHosts,
    setLoadingHosts,
    setSnackbar,
    selectedHosts,
    setSelectedHosts,
    handleSelectHostsChange,
    addSelectedHosts,
    removeSelectedHosts,
    index,
    handleOcultarGraficos,
    ocultarGraficos,
  } = props;

  const [host, setHost] = useState([]);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState([]);

  const [disableItems, setDisableItems] = useState(
    selectedHosts[index]?.nome ? false : true
  );
  const [loadingItems, setLoadinItems] = useState(false);

  /*useEffect(() => {
    if (selectedHosts[index]?.nome) {
      setHost(selectedHosts[index].nome);
    }
  }, [selectedHosts]);*/

  async function handleApiGetKeyItems(hostId) {
    try {
      setLoadinItems(true);

      const response = await api.post("/easyReport/Items/find", {
        hostId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival buscar Interfaces",
          severity: "error",
        });
      } else {
        setItems(response.data);
      }
    } catch (error) {
      console.error(error);

      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadinItems(false);
      setDisableItems(false);
    }
  }

  const getHostsLabel = (host) => host?.nome || "";

  const getInterfacesLabel = (item) => item?.nome || "";

  return (
    <Stack direction={"column"} gap={"8px"}>
      <Stack>
        <Typography color={"primary"}>Ocultar gráficos</Typography>
        <FormGroup sx={{ width: "fit-content" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ocultarGraficos}
                size="small"
                name="chart"
                onChange={(event) => {
                  handleOcultarGraficos(event);
                }}
              />
            }
            label="Ocultar gráficos"
          />
        </FormGroup>
      </Stack>
      <Typography color={"primary"}>Seleção de hosts e itens</Typography>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Autocomplete
          name={"host"}
          disabled={loadingHosts}
          size="small"
          sx={styles.autcomplete}
          value={
            {
              id: selectedHosts[index]?.id,
              nome: selectedHosts[index]?.nome,
            } || null
          }
          onChange={(event, newValue) => {
            let eventAutocompleteId = {
              target: { name: "id", value: newValue?.id || "" },
            };
            let eventAutocompleteName = {
              target: { name: "nome", value: newValue?.nome || "" },
            };

            let eventAutocompleteItems = {
              target: { name: "itens", value: [] },
            };
            handleSelectHostsChange(index, eventAutocompleteId);
            handleSelectHostsChange(index, eventAutocompleteName);
            handleSelectHostsChange(index, eventAutocompleteItems);
            setHost(newValue);
            if (newValue?.id) {
              handleApiGetKeyItems(newValue?.id);
            }
          }}
          options={hosts}
          getOptionLabel={getHostsLabel}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <>
              <TextField {...params} label="Selecione um Host" />
              <Fade in={loadingHosts}>
                <LinearProgress />
              </Fade>
            </>
          )}
        />
        {index === 0 ? (
          <span>
            <Tooltip title="Adiciona mais um host">
              <IconButton onClick={addSelectedHosts}>
                <Add color="primary" />
              </IconButton>
            </Tooltip>
          </span>
        ) : (
          <Tooltip title="Remove este host">
            <IconButton
              onClick={() => {
                removeSelectedHosts(index);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Autocomplete
          disableCloseOnSelect
          name={"itens"}
          disabled={disableItems}
          sx={styles.autcomplete}
          value={selectedHosts[index]?.itens || []}
          onChange={(event, newValue) => {
            let eventAutocompleteItems = {
              target: { name: "itens", value: newValue },
            };

            handleSelectHostsChange(index, eventAutocompleteItems);
          }}
          multiple
          defaultValue={[]}
          options={items}
          getOptionLabel={getInterfacesLabel}
          size="small"
          renderInput={(params) => (
            <>
              <TextField {...params} label="Selecione os Itens" />
              <Fade in={loadingItems}>
                <LinearProgress />
              </Fade>
            </>
          )}
        />
        <Tooltip title="Atualize a lista de interfaces" sx={styles.tooltip}>
          <span>
            <IconButton
              disabled={disableItems}
              onClick={() => {
                handleApiGetKeyItems(selectedHosts[index].id);
              }}
            >
              <Refresh
                sx={{
                  color: disableItems ? "chip.arquive" : "chip.waitingReview",
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
