import { useState, useRef } from "react";
import Papa from "papaparse";
import {
  Stack,
  Typography,
  Box,
  Button,
  Paper,
  CircularProgress,
  Dialog,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import {
  AttachFile,
  CheckCircle,
  Error,
  Pending,
  Close,
} from "@mui/icons-material";
import api from "../../../services/api";
import { Tooltip } from "@mui/material";
const stylesImportCsv = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
  },
  paper: {
    width: "84vw",
    maxWidth: "1550px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
    elevation: 3,
    gap: "20px",
  },
  button: { height: "40px", minWidth: "120px", m: 1 },
  filter: { width: "180px" },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  porta: { width: "105px" },
  textField: { width: "230px" },
  titles: { fontWeight: 500, fontSize: "20px" },
};
export default function AdicionaEmMassa(props) {
  const { predefinicoes, proxys, setSnackbar } = props;
  const fileRef = useRef();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [hosts, setHosts] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  //const [colunasAusentnes, setColunasAusentes] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    senhaTelnet: false,
    portaTelnet: false,
    userTelnet: false,
    tipoSnmp: false,
  });
  const [alertTitle, setAlertTitle] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const handleFileHosts = () => {
    let arr = hosts.map((host, index) => {
      const fabricanteAux = predefinicoes.filter((obj) => {
        return obj.fabricante
          ?.toLowerCase()
          .includes(host.fabricante.toLowerCase());
      });
      const funcaoAux = fabricanteAux[0].Funcoes?.filter((obj) => {
        return obj?.funcao?.toLowerCase().includes(host.funcao?.toLowerCase());
      });

      const modeloAux = funcaoAux[0].Modelos?.filter((obj) => {
        return obj?.modelo?.toLowerCase().includes(host.modelo?.toLowerCase());
      });

      let nomeHost = host?.nome
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      nomeHost = `${nomeHost?.trim()} - ${host?.ip?.trim()}`;
      return {
        //nome: `${host?.nome?.trim()} - ${host.ip.trim()}`,
        nome: nomeHost,
        fabricante: host.fabricante?.toUpperCase().trim(),
        funcao: host.funcao?.toUpperCase().trim(),
        modelo: modeloAux[0],
        coletor: host.coletor,
        userSsh: host.userSsh,
        portaSsh: parseInt(host.portaSsh),
        senhaSsh: host.userSsh,
        userTelnet: host.userTelnet,
        portaTelnet: parseInt(host.portaTelnet),
        senhaTelnet: host.senhaTelnet,
        interfaces: [
          {
            ip: host.ip,
            portaSnmp: parseInt(host.portaSnmp),
            tipoSnmp: parseInt(host.tipoSnmp),
            comunidade: host.comunidade,
          },
        ],
      };
    });

    return arr;
  };

  function validateHosts(colunas) {
    let colunaErro = [];
    const colunasTemplate = [
      "nome",
      "funcao",
      "fabricante",
      "modelo",
      "coletor",
      "userSsh",
      "senhaSsh",
      "portaSsh",
      "userTelnet",
      "portaTelnet",
      "senhaTelnet",
      "senhaTelnet",
      "tipoSnmp",
      "portaSnmp",
      "comunidade",
      "ip",
    ];
    colunaErro = colunasTemplate.filter((item) => !colunas.includes(item));
    return colunaErro;
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      hide: true,
    },
    { field: "nome", headerName: "NOME", width: 200, flex: 1 },
    { field: "fabricante", headerName: "FABRICANTE", width: 100 },
    { field: "funcao", headerName: "FUNÇÃO", width: 100 },
    { field: "modelo", headerName: "MODELO", width: 100 },
    { field: "coletor", headerName: "COLETOR", width: 150 },
    { field: "userSsh", headerName: "USER SSH", width: 100 },
    { field: "portaSsh", headerName: "PORTA SSH", width: 100 },
    { field: "senhaSsh", headerName: "SENHA SSH", width: 100 },
    { field: "userTelnet", headerName: "USER TELNET", width: 100 },
    { field: "portaTelnet", headerName: "PORTA TELNET", width: 100 },
    { field: "senhaTelnet", headerName: "SENHA TELNET", width: 100 },
    { field: "tipoSnmp", headerName: "TIPO SNMP", width: 100 },
    { field: "portaSnmp", headerName: "PORTA SNMP", width: 100 },
    { field: "comunidade", headerName: "COMUNIDADE", width: 100 },
    {
      field: "ip",
      headerName: "IP",
      width: 100,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 65,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value.erro ? params.value.erro : ""}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {params.value.codigo === 0 ? (
                <>
                  <Pending color="primary" />
                </>
              ) : params.value.codigo === 1 ? (
                <>
                  <CircularProgress size={20} />
                </>
              ) : params.value.codigo === 2 ? (
                <>
                  <CheckCircle color="success" />
                </>
              ) : params.value.codigo === 3 ? (
                <>
                  <Error color="error" />
                </>
              ) : null}
            </Box>
          </Tooltip>
        );
      },
    },
  ];
  const createHosts = async (hostsToApi) => {
    for (let i = 0; i < hostsToApi.length; i++) {
      const host = hostsToApi[i];

      setHosts((prevHosts) =>
        prevHosts.map((host, index) =>
          index === i ? { ...host, status: { codigo: 1, erro: "" } } : host
        )
      );
      try {
        const resposta = await api.post("/easyAdd/createMany", {
          hosts: [host],
        });
        setHosts((prevHosts) =>
          prevHosts.map((host, index) =>
            index === i ? { ...host, status: { codigo: 2, erro: "" } } : host
          )
        );
        // Registra o resultado (sucesso) no array de resultados
      } catch (error) {
        setHosts((prevHosts) =>
          prevHosts.map((host, index) =>
            index === i
              ? {
                  ...host,
                  status: { codigo: 3, erro: error.response.data.error },
                }
              : host
          )
        );
        setSnackbar({
          children: `Error:${error.message}`,
          severity: "error",
        });
      }
    }
  };
  async function handlaApiCreateManyHosts() {
    try {
      setLoading(true);
      let hostsToApi = handleFileHosts();
      const responseHosts = await createHosts(hostsToApi);
      // setTabValue(0);
    } catch (error) {
      console.error(error);
      setOpenAlertDialog(true);
      setAlertContent(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <Box sx={stylesImportCsv.container}>
        <Paper sx={stylesImportCsv.paper}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography color={"primary"} sx={stylesImportCsv.titles}>
              Preview
            </Typography>{" "}
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 3,
              }}
            >
              <Typography>{file ? file.name : ""}</Typography>

              <Button
                variant="contained"
                component="label"
                sx={{ maxWidth: "800px", width: "170px" }}
                startIcon={<AttachFile />}
                onClick={() => {
                  //setAddEmMasa(false);
                  //  setFile("");
                  //  setHosts([]);
                }}
              >
                Anexar CSV{" "}
                <input
                  hidden
                  type="file"
                  ref={fileRef}
                  className="file"
                  accept=" .xls, .xlsx, .csv"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    //console.log(e.target.files);

                    if (!e.target.files[0]) {
                      setSnackbar({
                        children: "Error: Não foi possível ler o arquivo",
                        severity: "error",
                      });
                    } else {
                      Papa.parse(e.target.files[0], {
                        header: true,
                        download: true,
                        complete: function (results) {
                          const colunsValidate = validateHosts(
                            results?.meta?.fields
                          );
                          if (
                            Array.isArray(colunsValidate) &&
                            colunsValidate.length > 0
                          ) {
                            setAlertContent(
                              colunsValidate.map(
                                (coluna, index) =>
                                  `${coluna}` +
                                  (index !== colunsValidate.length - 1 && `, `)
                              )
                            );
                            setAlertTitle("Colunas Ausentes:");
                            setFile("");
                            setOpenAlertDialog(true);
                            //setColunasAusentes(colunsValidate);
                          } else {
                            if (results.data && Array.isArray(results.data)) {
                              results.data.map(
                                (d, index) => (
                                  (d.status = {
                                    codigo: 0,
                                    erro: "",
                                  }),
                                  (d.id = index)
                                )
                              );
                              setHosts(results.data);
                            }
                          }
                        },
                      });
                    }
                  }}
                />
              </Button>
            </Box>
          </Stack>
          <DataGrid
            sx={{
              fontSize: 12,
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            //getRowHeight={() => "auto"}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={hosts}
            columns={columns}
            rowsPerPageOptions={[10, 50, 100]}
            initialState={{
              ...hosts.initialState,
              pagination: {
                pageSize: 10,
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            components={{ Toolbar: GridToolbar }}
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            disableRowSelectionOnClick={true}
          />{" "}
          <Stack sx={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => {
                handlaApiCreateManyHosts();
              }}
            >
              Salvar
            </Button>
          </Stack>
        </Paper>
      </Box>
      <Dialog
        open={openAlertDialog}
        onClose={() => {
          setAlertContent("");
          setAlertTitle("");
          setOpenAlertDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 3 }}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertContent("");
                setAlertTitle("");
                setOpenAlertDialog(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {alertTitle}
          <Box
            component={"p"}
            sx={{
              maxWidth: "500px" /* Defina a largura máxima desejada */,
              wordWrap:
                "break-word" /* Esta propriedade faz com que o texto quebre quando necessário */,
            }}
          >
            <strong> {alertContent}</strong>
          </Box>
          {/* <Button
            onClick={() => {
              setOpenAlertDialog(false);
            }}
            autoFocus
          >
            Fechar
          </Button>*/}
        </Alert>
      </Dialog>
    </>
  );
}
