import { WidthFullTwoTone } from "@mui/icons-material";
import {
  Paper,
  useTheme,
  Box,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as TitleBarSVG } from "../../../assets/easyMaps/titlebar.svg";
import { useState, useRef, useEffect } from "react";
import * as React from "react";
import api from "../../../services/api";
import AlertModal from "../../../componentes/alertModal";
import { useNavigate } from "react-router-dom";

const TitleBar = ({ title, id, setSnackbar, center }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [positionCenter, setPositionCenter] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [titleMap, setTitleMap] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [up, setUp] = useState(false);
  const inputRef = useRef();
  const barRef = useRef(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const titleMapRef = useRef();
  const upRef = useRef();
  useEffect(() => {
    titleMapRef.current = titleMap;
    upRef.current = up;
  }, [titleMap, up]);

  useEffect(() => {
    setTitleMap(title);
  }, [title]);
  useEffect(() => {
    if (editMode) inputRef.current.focus();
  }, [editMode]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    setEditMode(true);
  };
  const DeleteModal = (props) => <AlertModal {...props} />;
  function update() {
    let position = window.innerWidth - 690;
    setPositionCenter(position / 2 - 160);
  }
  useEffect(() => {
    update();
  }, [center]);

  useEffect(() => {
    window.addEventListener("resize", update);
    return () => {
      window.removeEventListener("resize", update);
    };
  }, []);

  const styles = {
    icon: {
      color: theme.palette.color.icons,
    },
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
    paper: {
      height: "auto",
      width: "320px",
      fill: theme.palette.color.appBarEasyMaps.backgroundColor,
    },
    box: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "360px",
      position: "absolute",
      left: 0,
      right: 0,
      background: "none",
      marginLeft: center ? positionCenter + "px" : "auto",
      marginRight: center ? null : "auto",
      zIndex: 1,
    },
    text: {
      maxWidth: "230px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    stack: {
      mt: "-32px",
      justifyContent: "center",
      alignItems: "center",
      gap: 1,
    },
  };

  async function handleApiDeleteMap() {
    setOpenDeleteModal(false);

    try {
      const response = await api.post("/easyMaps/map/delete", {
        mapId: id,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível deletar o mapa",
          severity: "error",
        });
        setTitleMap(title);
      } else {
        navigate("/easyMaps");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setUp(false);
    }
  }

  async function updateTitleMap() {
    if (!upRef.current) return;

    try {
      const response = await api.put("/easyMaps/map/updateTitle", {
        mapId: id,
        titulo: titleMapRef.current,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível atualizar o titulo",
          severity: "error",
        });
        setTitleMap(title);
      } else {
        setTitleMap(response.data.titulo);
        setSnackbar({
          children: "Título Atualizado com Sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setUp(false);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (barRef.current && !barRef.current.contains(event.target)) {
        setEditMode(false);
        updateTitleMap();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Box sx={styles.box} ref={barRef}>
        <TitleBarSVG style={styles.paper} />
        <Stack direction="row" sx={styles.stack}>
          {!editMode && (
            <>
              <Typography sx={styles.text}>{titleMap}</Typography>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              >
                <KeyboardArrowDownIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </>
          )}
          {editMode && (
            <TextField
              variant="standard"
              inputRef={inputRef}
              InputProps={{
                disableUnderline: true,
                sx: {
                  mt: "-2px",
                  width: 10 + titleMap?.length * 8 + "px",
                  maxWidth: "200px",
                },
              }}
              value={titleMap}
              onChange={(event) => {
                setUp(true);
                setTitleMap(event.target.value);
              }}
            />
          )}
        </Stack>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleEdit}>Renomear</MenuItem>
          <MenuItem
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            Deletar
          </MenuItem>
        </Menu>
      </Box>
      <DeleteModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        handleButton={handleApiDeleteMap}
        severity={"error"}
        singleButton={false}
      />
    </>
  );
};

export default TitleBar;
