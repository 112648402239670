import {
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Modal,
  Paper,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import AlertModal from "../../../../componentes/alertModal";

const styles = {
  filter: { width: "300px" },
  textField: { width: "180px" },
  comunidade: { width: "340px" },
  porta: { width: "100px" },
  titles: { fontWeight: 500, mt: 2 },
};

export default function ModalEditHost(props) {
  const {
    openEditModal,
    setOpenEditModal,
    setSnackbar,
    selectedRow,
    updateRow,
    predefinicoes,
    proxys,
    handleApiGetHosts,
  } = props;
  const [fabricante, setFabricante] = useState("");
  const [funcao, setFuncao] = useState("");
  const [modelo, setModelo] = useState("");
  const [nome, setNome] = useState("");
  const [interfaceid, setIerfaceId] = useState("");
  const [coletor, setColetor] = useState("");
  const [userSsh, setUserSsh] = useState("");
  const [portaSsh, setPortaSsh] = useState(22);
  const [senhaSsh, setSenhaSsh] = useState("");
  const [userTelnet, setUserTelnet] = useState("");
  const [portaTelnet, setPortaTelnet] = useState(23);
  const [senhaTelnet, setSenhaTelnet] = useState("");
  const [interfaces, setInterfaces] = useState([
    { ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "public" },
  ]);
  const [error, setError] = useState("");
  const [disableConfirmar, setDisableConfirmar] = useState(true);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  function validateIp(ip) {
    const parts = ip.split(".");

    if (ip === "") {
      return "";
    } else {
      if (parts.length !== 4) {
        return "Formato inválido de endereço IP.";
      }
      for (let i = 0; i < 4; i++) {
        const part = parseInt(parts[i]);
        if (isNaN(part) || part < 0 || part > 255) {
          return "Formato inválido de endereço IP.";
        }
      }
      return "";
    }
  }
  const handleInterfaceChange = (index, event) => {
    const { name, value } = event.target;
    const newInterfaces = [...interfaces];
    newInterfaces[index][name] = value;
    setInterfaces(newInterfaces);
  };
  const addInterface = () => {
    if (interfaces.length < 2) {
      setInterfaces([
        ...interfaces,
        { ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "" },
      ]);
    }
  };

  const removeInterface = (index) => {
    const newInterfaces = [...interfaces];
    newInterfaces.splice(index, 1);
    setInterfaces(newInterfaces);
  };
  function handlePredefinicoes() {
    if (selectedRow.fabricante) {
      const fabricanteAux = predefinicoes.filter(
        (obj) => (obj.fabricante).toLowerCase()  === (selectedRow.fabricante).toLowerCase() 
      );
      if (Array.isArray(fabricanteAux)) {
        setFabricante(
          selectedRow.fabricante === "desconhecido" ? "" : fabricanteAux[0]
        );
        const funcaoAux =
          fabricanteAux.length > 0
            ? fabricanteAux[0].Funcoes?.filter(
                (obj) => (obj.funcao).toLowerCase()  === (selectedRow.funcao).toLowerCase() 
              )
            : [];
        if (Array.isArray(funcaoAux)) {
         
          setFuncao(selectedRow.funcao === "desconhecido" ? "" : funcaoAux[0]);
          const modeloAux =
            funcaoAux.length > 0
              ? funcaoAux[0].Modelos?.filter(
                  (obj) => (obj.modelo).toLowerCase() === (selectedRow.modelo).toLowerCase()
                )
              : [];
          if (Array.isArray(modeloAux)) {
            setModelo(
              selectedRow.modelo === "desconhecido" ? "" : modeloAux[0]
            );
          }
        }
      }
    }
  }
  useEffect(() => {
    setNome(selectedRow?.nome?.replace(` - ${selectedRow.ip}`, ""))?.trim();
    handlePredefinicoes();
    setColetor(selectedRow.coletor);
    setUserSsh(selectedRow.userSsh);
    setPortaSsh(selectedRow.portaSsh);
    setSenhaSsh(selectedRow.senhaSsh);
    setUserTelnet(selectedRow.userTelnet);
    setPortaTelnet(selectedRow.portaTelnet);
    setSenhaTelnet(selectedRow.senhaTelnet);
    setIerfaceId(selectedRow.interfaceid);
    const newInterfaces = [...interfaces];
    newInterfaces[0].ip = selectedRow.ip;
    newInterfaces[0].portaSnmp = selectedRow.portaSnmp;
    newInterfaces[0].tipoSnmp = parseInt(selectedRow.tipoSnmp) || "";
    newInterfaces[0].comunidade = selectedRow.comunidade;
    setInterfaces(newInterfaces);
  }, [selectedRow]);
  useEffect(() => {
    if (
      nome &&
      interfaces[0]?.ip &&
      interfaces[0]?.portaSnmp &&
      interfaces[0]?.tipoSnmp &&
      interfaces[0]?.comunidade &&
      //coletor &&
      fabricante &&
      modelo &&
      funcao
    ) {
      setDisableConfirmar(false);
    } else {
      setDisableConfirmar(true);
    }
  }, [nome, interfaces, fabricante, modelo, coletor, funcao]);
  async function handleApi() {
    try {
      setDisableConfirmar(true);
      const response = await api.put("/easyAdd/update", {
        nome: `${nome} - ${interfaces[0]?.ip}`,
        fabricante: fabricante?.fabricante,
        funcao: funcao?.funcao,
        modelo,
        coletor,
        userSsh,
        portaSsh,
        senhaSsh,
        userTelnet,
        portaTelnet,
        senhaTelnet,
        interfaces,
        interfaceid,
        hostId: selectedRow.id,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possivel salvar dados",
          severity: "error",
        });
        setDisableConfirmar(false);
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
        handleApiGetHosts();
        setDisableConfirmar(false);
        setOpenEditModal(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
      setDisableConfirmar(false);
    } finally {
      setOpenEditDialog(false);
    }
  }

  return (
    <>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Paper sx={{ borderRadius: 3, boxShadow: 24 }}>
          <DialogTitle color="primary">
            Preencha os dados de edição de host
          </DialogTitle>
          <DialogContent>
            <Stack gap={"16px"} direction={"column"}>
              <Stack gap={"16px"} sx={{ mt: 1 }} direction={"row"}>
                <TextField
                  //disabled
                  id="nome-host"
                  variant="outlined"
                  label="Nome"
                  value={nome}
                  onChange={(event) => {
                    setNome(event.target.value);
                  }}
                  sx={styles.textField}
                />
                <Autocomplete
                  value={fabricante}
                  onChange={(event, newInputValue) => {
                    setFabricante(newInputValue);
                    setFuncao([]);
                    setModelo([]);
                  }}
                  freeSolo
                  options={predefinicoes?.map((options) => options)}
                  getOptionLabel={(options) => options.fabricante || ""}
                  sx={styles.textField}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fabricante"
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Autocomplete
                  disabled={!fabricante?.fabricante}
                  value={funcao}
                  onChange={(event, newInputValue) => {
                    setFuncao(newInputValue);
                    setModelo([]);
                  }}
                  freeSolo
                  options={fabricante?.Funcoes?.map((options) => options)}
                  getOptionLabel={(options) => options.funcao || ""}
                  sx={styles.textField}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Funções"
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Autocomplete
                  disabled={!funcao?.funcao}
                  value={modelo}
                  onChange={(event, newInputValue) => {
                    setModelo(newInputValue);
                  }}
                  freeSolo
                  options={funcao?.Modelos?.map((options) => options)}
                  getOptionLabel={(options) => options.modelo || ""}
                  sx={styles.textField}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Modelo"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Stack>

              <Stack direction={"row"} alignItems={"center"}>
                <Typography color={"primary"} sx={styles.titles}>
                  Interfaces
                </Typography>
                {/*      <IconButton size="small" onClick={addInterface}>
                  {interfaces.length >= 2 ? null : <Add color="primary" />}
                </IconButton> */}
              </Stack>
              {interfaces.map((value, index) => (
                <Stack key={index} gap={"16px"} direction={"row"}>
                  <TextField
                    id="ip"
                    name="ip"
                    variant="outlined"
                    label="IP"
                    value={interfaces[index].ip}
                    onChange={(event) => {
                      handleInterfaceChange(index, event);
                    }}
                    onBlur={() => setError(validateIp(interfaces[index].ip))}
                    error={Boolean(error)}
                    helperText={error}
                    sx={styles.textField}
                  />{" "}
                  <TextField
                    id="host-porta"
                    name="portaSnmp"
                    variant="outlined"
                    label="Porta SNMP"
                    type="number"
                    value={parseInt(interfaces[index].portaSnmp)}
                    onChange={(event) => {
                      handleInterfaceChange(index, event);
                    }}
                    sx={styles.porta}
                  />
                  <FormControl variant="outlined" sx={styles.porta}>
                    <InputLabel shrink>Tipo SNMP</InputLabel>
                    <Select
                      fullWidth
                      name="tipoSnmp"
                      label="Tipo SNMP"
                      value={interfaces[index].tipoSnmp}
                      onChange={(event) => {
                        handleInterfaceChange(index, event);
                      }}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1}>V1</MenuItem>
                      <MenuItem value={2}>V2</MenuItem>
                      <MenuItem value={3}>V3</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="comunidade-hosts"
                    sx={styles.comunidade}
                    variant="outlined"
                    label="Comunidade"
                    name="comunidade"
                    required
                    value={interfaces[index].comunidade}
                    onChange={(event) => {
                      handleInterfaceChange(index, event);
                    }}
                  />
                  {interfaces.length > 1 && (
                    <IconButton onClick={() => removeInterface(index)}>
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                </Stack>
              ))}

              <Typography color={"primary"} sx={styles.titles}>
                Monitorado por proxy
              </Typography>
              <Stack gap={"16px"} direction={"row"}>
                <FormControl variant="outlined" label="Coletor" required>
                  <InputLabel> Coletor</InputLabel>
                  <Select
                    fullWidth
                    value={coletor}
                    label="Coletor"
                    onChange={(event) => {
                      setColetor(event.target.value);
                    }}
                    sx={styles.filter}
                  >
                    <MenuItem value={""}>Sem proxy</MenuItem>
                    {Array.isArray(proxys) &&
                      proxys.map((p, index) => {
                        return <MenuItem value={p.host}>{p.host}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </Stack>

              <Typography color={"primary"} sx={styles.titles}>
                Acesso SSH
              </Typography>
              <Stack gap={"16px"} direction={"row"}>
                <TextField
                  id="host-user"
                  variant="outlined"
                  label="User SSH"
                  value={userSsh}
                  sx={styles.textField}
                  onChange={(event) => {
                    setUserSsh(event.target.value);
                  }}
                />
                <TextField
                  id="host-portaAcesso"
                  variant="outlined"
                  label="Porta SSH"
                  type="number"
                  value={portaSsh}
                  sx={styles.textField}
                  onChange={(event) => {
                    setPortaSsh(event.target.value);
                  }}
                />
                <TextField
                  id="host-senha"
                  variant="outlined"
                  label="Senha SSH"
                  value={senhaSsh}
                  sx={styles.textField}
                  onChange={(event) => {
                    setSenhaSsh(event.target.value);
                  }}
                />
              </Stack>
              <Typography color={"primary"} sx={styles.titles}>
                Acesso TELNET
              </Typography>
              <Stack gap={"16px"} direction={"row"}>
                <TextField
                  id="host-user"
                  variant="outlined"
                  label="User TELNET"
                  value={userTelnet}
                  sx={styles.textField}
                  onChange={(event) => {
                    setUserTelnet(event.target.value);
                  }}
                />
                <TextField
                  id="host-portaAcesso"
                  variant="outlined"
                  label="Porta TELNET"
                  type="number"
                  value={portaTelnet}
                  sx={styles.textField}
                  onChange={(event) => {
                    setPortaTelnet(event.target.value);
                  }}
                />
                <TextField
                  id="host-senha"
                  variant="outlined"
                  label="Senha TELNET"
                  value={senhaTelnet}
                  sx={styles.textField}
                  onChange={(event) => {
                    setSenhaTelnet(event.target.value);
                  }}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEditModal(false);
              }}
              color="secondary"
            >
              Cancelar
            </Button>

            <Button
              autoFocus
              disabled={disableConfirmar}
              onClick={() => {
                setOpenEditDialog(true);
              }}
            >
              confirmar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
      <AlertModal
        openModal={openEditDialog}
        setOpenModal={setOpenEditDialog}
        confirmar={disableConfirmar}
        handleButton={handleApi}
        severity={"warning"}
        singleButton={false}
      />
    </>
  );
}
