import { Box, Paper, Tabs, Tab, Snackbar, Alert } from "@mui/material";

import { useEffect, useState } from "react";
import PaginaInicial from "./paginaInicial";
import AdicaoEmMassa from "./adicaoEmMassa";
import ImportarCsv from "./importarCsv";
import api from "../../services/api";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "2rem",
  },
  paper: {
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingX: "20px",
  },
  box: { width: "100%" },
  titles: { fontWeight: 500 },
};

export default function EasyAdd(props) {
  const [tabValue, setTabValue] = useState(0);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPredefinicoes, setLoadingPredefinicoes] = useState(true);
  const [proxys, setProxys] = useState([]);
  const [rows, setRows] = useState([]);
  const [predefinicoes, setPredefinicoes] = useState([]);

  useEffect(() => {
    if (tabValue === 0) {
      handleApiGetHosts();
      handleApiGetPredefinicoes();
    }
  }, [tabValue]);
  async function handleApiGetPredefinicoes() {
    try {
      setLoadingPredefinicoes(true);
      const response = await api.get("/predefinicoes/findMany");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possível buscar os dados",
          severity: "error",
        });
        setLoadingPredefinicoes(false);
      } else {
        if (Array.isArray(response.data)) {
          setPredefinicoes(response.data || []);
        }
        setLoadingPredefinicoes(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingPredefinicoes(false);
      console.log(error);
    }
  }
  async function handleApiGetHosts() {
    try {
      setLoadingPage(true);
      const response = await api.get("/easyAdd/findMany");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível carregar dados",
          severity: "error",
        });
        setLoadingPage(false);
      } else {
        setRows(response.data.hosts);
        setProxys(response.data.proxys);

        setSnackbar({
          children: "Dados carregados com sucesso",
          severity: "success",
        });
        setLoadingPage(false);

        /*   updateRow({
            ...response.data,
            numero: selectedRow.numero,
          });*/
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
      setLoadingPage(false);
    }
  }
  return (
    <>
      <Box sx={styles.container}>
        <Paper elevation={3} sx={styles.paper}>
          <Tabs
            scrollButtons={true}
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            <Tab
              label={"Hosts"}
              iconPosition="start"
              value={0}
              sx={styles.tab}
            />
            <Tab
              label={"Adição Em Massa"}
              iconPosition="start"
              value={1}
              sx={styles.tab}
            />
            <Tab
              label={"Importar"}
              iconPosition="start"
              value={2}
              sx={styles.tab}
            />
          </Tabs>
        </Paper>
        <Box sx={styles.box}>
          {tabValue === 0 && (
            <PaginaInicial
              loadingPage={loadingPage}
              loadingPredefinicoes={loadingPredefinicoes}
              predefinicoes={predefinicoes}
              rows={rows}
              proxys={proxys}
              setRows={setRows}
              handleApiGetHosts={handleApiGetHosts}
              key={1}
            />
          )}
          {tabValue === 1 && (
            <AdicaoEmMassa
              loadingPredefinicoes={loadingPredefinicoes}
              predefinicoes={predefinicoes}
              proxys={proxys}
              setSnackbar={setSnackbar}
              key={2}
              setTabValue={setTabValue}
            />
          )}
          {tabValue === 2 && (
            <ImportarCsv
              loadingPredefinicoes={loadingPredefinicoes}
              predefinicoes={predefinicoes}
              proxys={proxys}
              setSnackbar={setSnackbar}
              setTabValue={setTabValue}
              key={3}
            />
          )}
        </Box>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
