import ReactFlow, {
  Background,
  ConnectionMode,
  MarkerType,
  ReactFlowProvider,
  addEdge,
  getNodesBounds,
  updateEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import ToolBarMaps from "../../../../componentes/easyMaps/toolbarMaps";
import ZoomFlow from "../../../../componentes/easyMaps/ZoomFlow";
import NodeMenu from "../../../../componentes/easyMaps/nodeMenu";
import { Box, Stack, useTheme } from "@mui/material";
import ElementNode from "../../../../componentes/easyMaps/elementNode";
import PositionableEdge from "../../../../componentes/easyMaps/positionableEdge";
import { useCallback, useEffect, useRef, useState } from "react";
import AlertModal from "../../../../componentes/alertModal";
import ContextMenu from "../../../../componentes/easyMaps/contextMenu";
import FilterElements from "../../../../componentes/easyMaps/filterElements";
import TitleBarPublic from "../../../../componentes/easyMaps/titleBarPublic";
import DescriptionBar from "../../../../componentes/easyMaps/descriptionBar";
import RightBarEasyMaps from "../../../../componentes/easyMaps/rightBarEasyMaps";
import EnlaceSideBarEasyMaps from "../../../../componentes/easyMaps/enlaceSidebarEasyMaps";
import CreationSliderBarEasyMaps from "../../../../componentes/easyMaps/creationSidebarEasymaps";
import api from "../../../../services/api";
import { useParams } from "react-router-dom";
import { select } from "d3-selection";
import ToolBarMapsPublic from "../../../../componentes/easyMaps/toolbarMapsPublic";
const nodeTypes = {
  element: ElementNode,
};
const edgeTypes = {
  positionableedge: PositionableEdge,
};
const ReactFlowMapas = (props) => {
  const {
    connections,
    setEdgeSelected,
    elements,
    elementSelected,
    setElementSelected,
    setElementsConnected,
    connectionSelected,
    setConnectionSelected,
    iconsElements,
    setOpenRightBar,
    focusNodeId,
    fullScreem,
    setFullScreem,
    mapId,
    title,
    setSnackbar,
    setCoordenadasFlow = () => {},
    setIconsElements,
    edgeSelected,
    openRightBar,
    setFocusNodeId,
  } = props;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const bounds = getNodesBounds(nodes);
  const [lockMode, setLockMode] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  const [elementsFilter, setElementsFilter] = useState([]);
  const [openConnection, setOpenConnection] = useState(null);
  const [showLabelEnlace, setShowLabelEnlace] = useState(true);
  const ref = useRef();
  const { id, token } = useParams();
  const theme = useTheme();
  const styles = {
    stackContainer: {
      position: fullScreem ? "fixed" : "relative",
      top: 0,
      left: 0,
      width: fullScreem ? "100vw" : "100%",
      height: fullScreem ? "100vh" : "calc(100vh - 70px)",
      zIndex: fullScreem ? 1202 : 0,
      background: theme.palette.background.default,
      overflow: "visible",
    },
  };
  useEffect(() => {
    setCoordenadasFlow({
      x: bounds?.x + bounds?.width / 2,
      y: bounds?.y + bounds?.height / 2,
    });
  }, [nodes]);
  useEffect(() => {
    setElementSelected(null);
    setConnectionSelected(null);
    setElementsConnected(null);
    setNodes(handleFormaterNodes(elements, showLabel));
    setEdges(handleFormaterEdges(connections, showLabel));
  }, [showLabel, showLabelEnlace, elementsFilter]);

  useEffect(() => {
    if (!elements && !connections) return;
    setNodes(handleFormaterNodes(elements, showLabel));
    setEdges(handleFormaterEdges(connections, showLabel));
  }, [elements, connections]);

  //-----funcoes para formatação de dados
  const handleFormaterNodes = (
    elements,
    showLabel = true,
    entangled = true,
    selected = false
  ) => {
    const filter = elementsFilter?.map((value) => value.tipo);
    const newElements =
      elementsFilter?.length > 0
        ? elements.filter(({ tipo }) => filter.includes(tipo))
        : elements;

    const result = newElements.map(
      ({ id, elementConfig, titulo, alerts, img }) => {
        return {
          id,
          type: "element",
          position: {
            x: parseFloat(elementConfig?.config?.coordenadas?.long),
            y: parseFloat(elementConfig?.config?.coordenadas?.lat),
          },
          selected: elementSelected?.id === id ? true : selected,
          data: {
            img: iconsElements.find((ele) => ele.id == img)?.img,
            editMode: false,
            showLabel,
            entangled,
            title: titulo,
            hosts: elementConfig?.config?.hosts,
            tipoMapa: 1,
            alerts,
            value: 123,
          },
        };
      }
    );
    return result;
  };
  const handleFormaterEdges = (
    conections,
    showLabel = true,
    entangled = true
  ) => {
    const result = conections.map(
      ({ id, referenceId, destinationId, positionHandlers, config, data }) => {
        return {
          id,
          source: referenceId,
          type: "positionableedge",
          animation: true,
          sourceHandle: config?.sourceHandle,
          targetHandle: config?.targetHandle,
          target: destinationId,
          animated: config?.animation !== "desativada",
          updatable: "target",
          markerEnd: false
            ? {
                type: MarkerType.ArrowClosed,
                color: "#5e5e5e",
              }
            : "",
          labelStyle: { fill: "#26721f", fontWeight: 700 },
          selected: connectionSelected?.id === id ? true : false,
          data: {
            itens: data,
            editMode: false,
            showLabel,
            showLabelEnlace,
            entangled,
            lineType: config?.type,
            capacidade: config?.capacidade,
            positionHandlers: JSON.parse(JSON.stringify(positionHandlers)), //perigo eminente, sob hipótese nenhuma mexa nessa linha de código!
            label: "999.99Mbps",
            animation: config?.animation,
          },
        };
      }
    );
    return result;
  };

  const updateMemberStatus = (elements, ids, updateData) => {
    return elements.map((element) => ({
      ...element,
      data: {
        ...element.data,
        ...updateData(ids.includes(element.id)),
      },
    }));
  };

  const onNodeClick = (event, node) => {
    setConnectionSelected(null);
    setOpenConnection(null);
    setEdgeSelected(null);
    const selected = elements?.find(({ id }) => id === node.id);
    if (selected?.id === elementSelected?.id) {
      setElementSelected(null);
      return;
    }
    setElementSelected(selected);

    let idsElements = [selected.id];
    let idsConnections = [];
    let elementsConnect = [];

    connections.forEach(({ referenceId, destinationId, id }) => {
      if (node.id === destinationId || node?.id === referenceId) {
        const findElement = elements.find(
          ({ id }) =>
            id === (node.id === destinationId ? referenceId : destinationId)
        );
        elementsConnect.push(findElement);
        idsElements.push(findElement?.id);
        idsConnections.push(id);
      }
    });
    setElementsConnected(elementsConnect);

    const updateEntangled = (isEntangled) => ({ entangled: isEntangled });

    setNodes((nds) => updateMemberStatus(nds, idsElements, updateEntangled));
    setEdges((eds) => updateMemberStatus(eds, idsConnections, updateEntangled));
    setOpenRightBar(true);
    return;
  };
  const onEdgeClick = (event, edge) => {
    setElementSelected(null);
    const selected = connections.find(({ id }) => id === edge.id);

    setConnectionSelected(selected);

    setElementSelected(elements.find(({ id }) => id === selected?.referenceId));
    setOpenConnection(selected?.id);
  };

  const onPaneClick = (e) => {
    setConnectionSelected(null);
    setOpenConnection(null);
    setEdgeSelected(null);
    setNodes(handleFormaterNodes(elements, showLabel));
    setEdges(handleFormaterEdges(connections, showLabel));
    setElementsConnected(null);

    if (elementSelected || edgeSelected) {
      setEdgeSelected(null);
      setConnectionSelected(null);
      setElementSelected(null);
    }
  };

  return (
    <>
      <Box sx={{ overflow: "visible", zIndex: 1203 }}>
        <TitleBarPublic
          title={title}
          center={elementSelected != null || connectionSelected != null}
        />
      </Box>
      <Stack sx={styles.stackContainer}>
        <ReactFlowProvider>
          <ToolBarMapsPublic
            fullScreem={fullScreem}
            setFullScreem={setFullScreem}
            showLabel={showLabel}
            setShowLabel={setShowLabel}
            setShowLabelEnlace={setShowLabelEnlace}
            showLabelEnlace={showLabelEnlace}
            tipo={1}
          >
            <ToolBarMapsPublic.InputZoomTop />
          </ToolBarMapsPublic>
          <DescriptionBar />
          <FilterElements
            iconsElement={iconsElements}
            elementsFilter={elementsFilter}
            setElementsFilter={setElementsFilter}
          />
          <ZoomFlow
            id={id}
            focusNodeId={focusNodeId}
            setFocusNodeId={setFocusNodeId}
          />
          <ReactFlow
            ref={ref}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            snapToGrid={false}
            onPaneClick={onPaneClick}
            elementsSelectable={!lockMode}
            nodesConnectable={false}
            nodesDraggable={false}
            attributionPosition="bottom-left"
            fitView
            connectionMode={ConnectionMode.Loose}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            minZoom={0.5}
            maxZoom={3}
            proOptions={{ hideAttribution: true }}
          >
            <Background />
          </ReactFlow>{" "}
          <RightBarEasyMaps
            setIconsElements={setIconsElements}
            connectionsFull={connections}
            setFocusNodeId={setFocusNodeId}
            open={openRightBar}
            setOpen={setOpenRightBar}
            elements={elements}
            connections={connections}
            setElement={setElementSelected}
            setConnections={setElementsConnected}
            iconsElement={iconsElements}
            element={elementSelected?.id ? elementSelected : null}
            fullScreem={fullScreem}
            openConnection={openConnection}
            setOpenConnection={setOpenConnection}
            link={`/easyMaps/public/${
              elementSelected?.mapRef?.tipo === 0 ? "geo" : "top"
            }/${elementSelected?.mapRefId ?? ""}/${token}`}
          />
        </ReactFlowProvider>{" "}
      </Stack>{" "}
    </>
  );
};
export default ReactFlowMapas;
