import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  DialogActions,
  Divider,
  useTheme,
  Snackbar,
  Alert,
  IconButton
} from "@mui/material";

import { useEffect, useState } from "react";
import MapaModal from "../mapaModal";
import api from "../../../../services/api";
import Enlace from "./enlace";
import LoadingPage from "../../../../componentes/loadingPage";
import AlertModal from "../../../../componentes/alertModal";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  dialogContent: {
    flexGrow: 1
  },
  contentBox: { mt: 1, display: "flex", justifyContent: "space-between" },
  enlaceBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
    overflow: "auto",
    paddingRight: 1,
    height: "43.5vh"
  },
  formBox: { mt: 2, display: "flex" },

  interfaceBox: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%"
  },

  boxMapa: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    flexGrow: 1,
    minWidth: "50%",
    minHeight: "43.5vh"
  }
});

export default function EditModal(props) {
  const { setOpenModalUpdate, mapa, updateMapa, deleteMapa } = props;
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingConfirmarButton, setLoadingConfirmarButton] = useState(false);
  const [disableConfirmar, setDisableConfirmar] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const [titulo, setTitulo] = useState(mapa.titulo);

  const initialEnlace = mapa.enlaces;
  const [enlaces, setEnlaces] = useState(initialEnlace);
  const [hosts, setHosts] = useState([]);
  const [loadingInterfaces, setLoadingInterfaces] = useState(
    initialEnlace.map(() => false)
  );

  function updateLoadingInterfaces(index, value = true) {
    setLoadingInterfaces((prevArray) =>
      prevArray.map((item, i) => (i === index ? value : item))
    );
  }

  const isAnyFieldEmpty =
    titulo &&
    enlaces.every(
      (enlace) =>
        enlace.hostEntrada &&
        enlace.hostSaida &&
        enlace.interface &&
        enlace.capacidade &&
        enlace.coordenadaEntradaX &&
        enlace.coordenadaEntradaY &&
        enlace.coordenadaSaidaX &&
        enlace.coordenadaSaidaY
      // Adicione outras verificações aqui conforme necessário
    )
      ? true
      : false;

  useEffect(() => {
    async function initialRoutes() {
      try {
        handleApiGetHosts();
        setLoading(true);
      } catch (error) {
        console.erro(error);
      }
    }
    initialRoutes();
  }, []);

  async function handleApiGetHosts() {
    try {
      const response = await api.get("/easyReport/HostName/find", {});

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possivel buscar dados",
          severity: "error"
        });
      } else {
        setHosts(response?.data);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error"
      });

      console.error(error);
    }
  }

  async function handleUpdateMapa() {
    try {
      setLoadingConfirmarButton(true);
      const response = await api.put("/mapas/update", {
        id: mapa.id,
        titulo,
        enlaces
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possivel salvar dados",
          severity: "error"
        });
      } else {
        updateMapa(response.data);

        setOpenModalUpdate(false);
      }
      setLoadingConfirmarButton(false);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error"
      });
      setLoadingConfirmarButton(false);
      console.error(error);
    }
  }

  async function handledeleteMapa() {
    try {
      setDisableConfirmar(true);
      const response = await api.put("/mapas/delete", {
        id: mapa.id
      });
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possivel excluir dados",
          severity: "error"
        });
      } else {
        deleteMapa(mapa.id);
        setOpenEditDialog(false);
      }
      setLoadingConfirmarButton(false);
    } catch (error) {
      if (error.code) {
        setSnackbar({
          children: `Error: ${error.message}`,
          severity: "error"
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar com o servidor",
          severity: "error"
        });
      }
      //setLoadingConfirmarButton(false);

      console.error(error);
    } finally {
      setDisableConfirmar(false);
    }
  }

  //const memorizedEnlaces = useMemo(() => enlaces, [enlaces]);

  function clearModal() {
    setTitulo(mapa.titulo);
    setEnlaces(mapa.enlaces);
  }

  const handleEnlaceChange = (index, event) => {
    const { name, value } = event.target;

    const newEnlaces = [...enlaces];
    newEnlaces[index][name] = value;
    setEnlaces(newEnlaces);
  };

  const addEnalce = () => {
    if (enlaces.length) {
      setEnlaces([
        ...enlaces,
        {
          hostEntrada: "",
          hostSaida: "",
          interface: "",
          coordenadaEntradaX: null,
          coordenadaEntradaY: null,
          coordenadaSaidaX: null,
          coordenadaSaidaY: null,
          capacidade: null
        }
      ]);
    }
  };

  const removeEnlace = (index) => {
    const newEnlaces = [...enlaces];
    newEnlaces.splice(index, 1);
    setEnlaces(newEnlaces);
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: 2
        }}
      >
        {!loading && (
          <>
            <Box
              sx={{
                minHeight: "400px",
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <LoadingPage />
            </Box>
          </>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 2
            }}
          >
            <Box sx={styles.boxMapa}>
              <MapaModal enlaces={enlaces} />
            </Box>
            <Box sx={styles.dialogContent} display={"inline-block"}>
              <Box>
                <Stack
                  direction={"row"}
                  gap={1}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography color={"primary"} variant="h6">
                    Editar mapa{" "}
                  </Typography>

                  <Button
                    onClick={() => {
                      setOpenEditDialog(true);
                      //deleteMapa(mapa.id);
                    }}
                    color="error"
                    endIcon={<Delete sx={{ mt: -0.3 }} />}
                  >
                    Excluir mapa
                  </Button>
                </Stack>
                <Box sx={styles.contentBox}>
                  <TextField
                    label={"Título"}
                    size="small"
                    autoComplete="off"
                    value={titulo}
                    onChange={(event) => {
                      setTitulo(event.target.value);
                    }}
                  />
                </Box>
                <Box sx={styles.formBox}>
                  <Box sx={styles.interfaceBox}>
                    <Box>
                      <Button
                        onClick={addEnalce}
                        endIcon={<Add sx={{ mt: -0.3 }} />}
                      >
                        <Typography
                          variant="subtitle1"
                          color={"primary.main"}
                          fontWeight={500}
                          textTransform={"initial"}
                        >
                          Novo enlance
                        </Typography>
                      </Button>
                    </Box>
                    <Divider variant="middle" />
                    <Box sx={styles.enlaceBox}>
                      {Array.isArray(enlaces) &&
                        enlaces.map((enlace, index) => {
                          return (
                            <Stack
                              direction={"row"}
                              alignItems={"start"}
                              gap={2}
                              key={index}
                            >
                              <Enlace
                                index={index}
                                key={`enlace-${index}`}
                                enlaces={enlaces}
                                hosts={hosts}
                                handleEnlaceChange={handleEnlaceChange}
                                setSnackbar={setSnackbar}
                                updateLoadingInterfaces={
                                  updateLoadingInterfaces
                                }
                              />
                              {enlaces.length > 1 && (
                                <IconButton
                                  onClick={() => removeEnlace(index)}
                                  sx={{ mt: 3 }}
                                >
                                  <Delete color="secondary" />
                                </IconButton>
                              )}
                            </Stack>
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
                <DialogActions>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setOpenModalUpdate(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleUpdateMapa}
                    disabled={
                      loadingConfirmarButton ||
                      !loadingInterfaces.every((item) => item) ||
                      !isAnyFieldEmpty
                    }
                  >
                    Confirmar
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <AlertModal
        openModal={openEditDialog}
        setOpenModal={setOpenEditDialog}
        confirmar={disableConfirmar}
        handleButton={handledeleteMapa}
        severity={"error"}
        singleButton={false}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
