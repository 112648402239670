import { Paper, useTheme, IconButton, Divider, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SubtitlesOffOutlinedIcon from "@mui/icons-material/SubtitlesOffOutlined";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import { useReactFlow } from "reactflow";
import { LabelOffOutlined, LabelOutlined } from "@mui/icons-material";

const ToolBarMapsPublic = ({
  setFullScreem,
  fullScreem = false,
  setShowLabel,
  showLabel = true,
  setShowLabelEnlace,
  showLabelEnlace = true,
  children,
  tipo = 0,
}) => {
  const theme = useTheme();

  const styles = {
    icon: {
      color: theme.palette.color.icons,
    },
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
    card: {
      padding: "5px",
      position: "absolute",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      left: "15px",
      top: "15px",
      alignItems: "center",
      gap: 0.5,
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
      zIndex: 10,
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <Paper elevation={0} sx={styles.card}>
      {children}
      <Paper
        sx={[
          styles.paper,
          {
            backgroundColor: fullScreem ? theme.palette.primary.main : null,
          },
        ]}
      >
        <Tooltip title="Tela Cheia" placement="right">
          <IconButton
            aria-label="delete"
            sx={styles.button}
            onClick={() => setFullScreem(!fullScreem)}
          >
            {fullScreem ? (
              <FullscreenExitIcon />
            ) : (
              <FullscreenIcon sx={styles.icon} />
            )}
          </IconButton>
        </Tooltip>
      </Paper>
      <Paper
        sx={[
          styles.paper,
          { backgroundColor: !showLabel ? theme.palette.primary.main : null },
        ]}
      >
        <Tooltip
          title={showLabel ? "Ocultar Detalhes" : "Mostrar detalhes"}
          placement="right"
        >
          <IconButton
            aria-label="delete"
            sx={styles.button}
            onClick={() => setShowLabel(!showLabel)}
          >
            {showLabel ? (
              <SubtitlesOutlinedIcon sx={styles.icon} />
            ) : (
              <SubtitlesOffOutlinedIcon
                sx={[styles.icon, { color: "white" }]}
              />
            )}
          </IconButton>
        </Tooltip>
      </Paper>
      {/* <Paper
        sx={[
          styles.paper,
          { backgroundColor: lockMode ? theme.palette.primary.main : null },
        ]}
      >
        <IconButton
          aria-label="delete"
          sx={styles.button}
          onClick={() => setLockMode(!lockMode)}
        >
          {lockMode ? (
            <LockOutlinedIcon sx={[styles.icon, { color: "white" }]} />
          ) : (
            <LockOpenOutlinedIcon sx={styles.icon} />
          )}
        </IconButton>
      </Paper> */}
      {tipo === 1 && (
        <Paper
          sx={[
            styles.paper,
            {
              backgroundColor: !showLabelEnlace
                ? theme.palette.primary.main
                : null,
            },
          ]}
        >
          <IconButton
            aria-label="delete"
            sx={styles.button}
            onClick={() => setShowLabelEnlace(!showLabelEnlace)}
          >
            {showLabelEnlace ? (
              <LabelOutlined sx={styles.icon} />
            ) : (
              <LabelOffOutlined sx={[styles.icon, { color: "white" }]} />
            )}
          </IconButton>
        </Paper>
      )}
    </Paper>
  );
};
const InputZoomTop = () => {
  const { zoomIn, zoomOut } = useReactFlow();
  const styles = {
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
  };
  return (
    <Paper sx={styles.paper}>
      <IconButton
        aria-label="delete"
        onClick={() => zoomIn()}
        sx={styles.button}
      >
        <AddIcon sx={styles.icon} />
      </IconButton>
      <Divider sx={{ width: "80%" }} />
      <IconButton
        aria-label="delete"
        onClick={() => zoomOut()}
        sx={styles.button}
      >
        <RemoveIcon sx={styles.icon} />
      </IconButton>
    </Paper>
  );
};
const InputZoomGeo = ({ zoom }) => {
  const styles = {
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
  };
  return (
    <Paper sx={styles.paper}>
      <IconButton
        aria-label="delete"
        onClick={() => zoom(1)}
        sx={styles.button}
      >
        <AddIcon sx={styles.icon} />
      </IconButton>
      <Divider sx={{ width: "80%" }} />
      <IconButton
        aria-label="delete"
        onClick={() => zoom(-1)}
        sx={styles.button}
      >
        <RemoveIcon sx={styles.icon} />
      </IconButton>
    </Paper>
  );
};
ToolBarMapsPublic.InputZoomGeo = InputZoomGeo;
ToolBarMapsPublic.InputZoomTop = InputZoomTop;
export default ToolBarMapsPublic;
