import {
  Paper,
  Stack,
  Typography,
  Button,
  Box,
  Modal,
  useTheme,
  DialogContent,
  Card,
  CardActionArea,
  DialogTitle,
  DialogActions,
  Dialog,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Resizer from "react-image-file-resizer";
import ImageIcon from "@mui/icons-material/Image";

export default function ModalIcon({
  openModalImg,
  setOpenModalImg,
  api,
  setSnackbar,
  setIconElement,
  setIconsElement,
  iconsElementNames,
}) {
  const theme = useTheme();
  const [img, setImg] = useState(null);
  const [nome, setNome] = useState("");

  const validate = () => {
    return (
      !iconsElementNames.includes(nome) && !nome.startsWith("_") && img != null
    );
  };

  async function createIcon() {
    if (!validate()) return;
    let logs = JSON.parse(localStorage.getItem("logs"));
    try {
      const response = await api.post("easyMaps/img/create", {
        nome: nome,
        img: img,
        usuarioClienteId: logs.usuarioClienteId,
        logsLoginClienteId: logs.id,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível buscar os hosts",
          severity: "error",
        });
      } else {
        let data = { nome: nome, id: response.data.id, img: img };
        setIconElement(data);
        setIconsElement((icons) => {
          return [...icons, data];
        });
        setSnackbar({
          children: "Icone adicionado com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function processarFotos(file) {
    if (!file) return;

    const uri = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "PNG,JPEG",
        100,
        0,
        (resizedUri) => {
          resolve(resizedUri);
        },
        "base64"
      );
    });
    setImg(uri);
    setNome(file.name.split(".")[0]);
  }

  const handleClose = () => {
    setImg(null);
    setOpenModalImg(false);
  };
  const handleAdd = () => {
    if (!validate()) return;
    createIcon();
    setImg(null);
    setOpenModalImg(false);
  };

  return (
    <Dialog open={openModalImg} onClose={handleClose}>
      <DialogContent>
        <DialogTitle>Adição de icones</DialogTitle>
        <DialogContent>
          <Stack
            gap={3}
            sx={{ padding: "20px 0px" }}
            alignItems="center"
            justifyContent="center"
            width="300px"
          >
            {img ? (
              <>
                <img src={img} width="100px" height="auto" />

                <TextField
                  value={nome}
                  onChange={(value) => setNome(value.target.value)}
                  color="primary"
                  id="hostName"
                  label="Nome do icon"
                  variant="outlined"
                  size="small"
                  error={
                    nome?.startsWith("_") || iconsElementNames.includes(nome)
                  }
                  helperText={
                    (nome.startsWith("_") &&
                      "O nome do icon não deve começar com '_' !") ||
                    (iconsElementNames.includes(nome) &&
                      "Esse nome já está sendo utilizado!")
                  }
                />
              </>
            ) : (
              <ImageIcon sx={{ fontSize: "80px", color: "#7c7c7c1e" }} />
            )}

            <Button
              variant="contained"
              component="label"
              sx={{ width: "200px" }}
              startIcon={<CloudUploadIcon />}
            >
              {img ? "Reupload" : "Upload"}
              <input
                hidden
                accept=".png, .jpg, .jpeg"
                type="file"
                onChange={(e) => {
                  processarFotos(e?.target?.files[0]);
                }}
              />
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAdd} sx={{ color: theme.palette.color.text }}>
            Adicionar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
