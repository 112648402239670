import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Radio,
  Stack,
  Tab,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useState } from "react";

const styles = {
  confItensTextField: {
    width: "130px",
    "&::-webkit-timer-picker-indicator": {
      display: "none",
      "-webkit-appearance": "none"
    }
  },
  boxTabList: { borderRight: 1, borderColor: "divider" },
  tabPanel: { maxWidth: "1100px" },
  itensName: { opacity: 0.8, fontSize: "14px" },
  formGroup: {
    gap: 3,
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "500px",
    alignItems: "start"
  },
  boxFormGroup: {},
  formControlLabel: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    gap: 1
  },
  checkbox: { padding: "0" }
};

export default function ConfigureOsItens(props) {
  const { listTags, listItens, handleListItensChange } = props;

  const [tabValue, setTabValue] = useState("0");
  const theme = useTheme();

  const tabsText = [
    {
      label: "Valor Máximo",
      description: "Exibe o valor máximo dentro do período de coleta",
      key: "valorMaximo",
      tag: ["traffic", "number"]
    },
    {
      label: "Valor Mínimo",
      description: "Exibe o valor mínimo dentro do período de coleta",
      key: "valorMinimo",
      tag: ["traffic", "number"]
    },
    {
      label: "Valor Médio",
      description: "Exibe a média dos valores dentro do período de coleta",
      key: "valorMedio",
      tag: ["traffic", "number"]
    },
    {
      label: "Taxa de disponibilidade",
      description:
        "Exibe os períodos em que os hosts estiveram disponíveis e indisponíveis",
      key: "disponibilidade",
      tag: ["traffic", "boolean", "number"]
    },
    {
      label: "Horário personalizado",
      description:
        "Restringe a análise de dados somente ao intervalo de tempo selecionado durante o período de coleta",
      key: "horarioPersonalizado",
      tag: ["traffic", "boolean", "number"]
    },
    {
      label: "Considerar zero (bytes) de tráfego como indisponível",
      description:
        "Caso o item seja interface virtual e não tenha insdisponibilidade, considerar valores de tráfego nulos como indisponibilidade do host",
      key: "zeroDown",
      tag: ["traffic"]
    },
    {
      label: "95th (noventa e cinco percentil)",
      description:
        "Exibe tarifa de uso baseada no método de cobrança 95 percentil",
      key: "tarifacao",
      tag: ["traffic"]
    },
    {
      label: "95th com cobrança por excedente",
      description:
        "Exibe tarifa de uso baseada no método de cobrança 95 percentil com adição de cobrança extra para caso de consumo excedente",
      key: "tarifacaoExcedente",
      tag: ["traffic"]
    }
    /*   {
      label: "Variação abrupta",
      description: "Adiciona análise de quedas e subidas abruptas ao relatório",
      key: "variacaoAbrupta",
    }, */
  ];

  return (
    <TabContext value={tabValue}>
      <Box sx={styles.boxTabList}>
        <TabList
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
        >
          {listTags.map((item, index) => (
            <Tab
              label={
                item.tag === "traffic"
                  ? "Itens de Tráfego"
                  : item.tag === "boolean"
                  ? "Itens Booleanos"
                  : "Itens genéricos"
              }
              value={`${index}`}
              key={item.key_}
            />
          ))}
        </TabList>
      </Box>
      {listTags.map((item, index) => (
        <TabPanel
          value={`${index}`}
          key={`${item.tag}-${index}`}
          sx={styles.tabPanel}
        >
          <Stack direction={"column"} gap={1} mb={2} ml={-1}>
            <Typography>
              Os seguintes itens seguirão as configuações a abaixo:{" "}
            </Typography>
            <Typography sx={styles.itensName}>
              {listItens
                ?.filter((listItem) => listItem.tag === item.tag)
                ?.map(
                  (listItem, index) =>
                    `${listItem.name}${
                      listItens?.filter((listItem) => listItem.tag === item.tag)
                        .length -
                        1 ===
                      index
                        ? "."
                        : ","
                    } `
                )}
            </Typography>
          </Stack>

          <FormGroup
            sx={styles.formGroup}
            key={`FormGroup ${item.nome}-${index}`}
          >
            {tabsText.map((text, subIndex) => {
              if (text?.tag?.includes(item?.tag)) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      gap: 1,
                      flexDirection: "row",
                      width: subIndex > 4 ? "460px" : "auto"
                    }}
                    key={`${item.nome}-${index}-${subIndex}`}
                  >
                    <FormControlLabel
                      sx={styles.formControlLabel}
                      key={`FormControlLabel-${item.nome}-${index}-${subIndex}`}
                      control={
                        text.key === "horarioPersonalizado" ||
                        text.key === "variacaoAbrupta" ? (
                          <Checkbox
                            name={text.key}
                            checked={
                              listTags[index]?.["configuracoes"]?.[text.key]
                                ? listTags[index]?.["configuracoes"]?.[text.key]
                                    ?.status
                                : false
                            }
                            onChange={(event) => {
                              handleListItensChange(index, event);
                            }}
                            sx={styles.checkbox}
                          />
                        ) : text.key === "tarifacao" ||
                          text.key === "tarifacaoExcedente" ? (
                          <Radio
                            name={text.key}
                            checked={
                              listTags[index]?.configuracoes?.[text.key].status
                                ? listTags[index]?.configuracoes?.[text.key]
                                    .status
                                : false
                            }
                            onChange={(event) => {
                              handleListItensChange(index, event);
                            }}
                            value={true}
                            sx={styles.checkbox}
                          />
                        ) : (
                          <Checkbox
                            name={text.key}
                            checked={
                              listTags[index]?.configuracoes?.[text.key]
                                ? listTags[index]?.configuracoes?.[text.key]
                                : false
                            }
                            onChange={(event) => {
                              handleListItensChange(index, event);
                            }}
                            sx={styles.checkbox}
                          />
                        )
                      }
                      label={
                        <Stack direction={"row"}>
                          <Stack direction={"column"} sx={{ width: "250px" }}>
                            <Typography>{text?.label || ""}</Typography>
                            <Typography
                              sx={{
                                opacity: 0.61,
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "175%",
                                letterSpacing: "0.15px"
                              }}
                            >
                              {text?.description || ""}
                            </Typography>
                          </Stack>
                        </Stack>
                      }
                    />
                    <Stack direction={"column"} gap={2}>
                      {text?.key === "tarifacao" &&
                        listTags[index]?.configuracoes?.tarifacao?.status && (
                          <TextField
                            name={"tarifacao-valor"}
                            label={`valor por Mbps`}
                            type="number"
                            size="small"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              )
                            }}
                            value={
                              listTags[index]?.configuracoes?.tarifacao?.valor
                                ? listTags[index]?.configuracoes?.tarifacao
                                    ?.valor
                                : null
                            }
                            onChange={(event) => {
                              handleListItensChange(index, event);
                            }}
                            sx={styles.confItensTextField}
                          />
                        )}
                      {text?.key === "tarifacaoExcedente" &&
                        listTags[index]?.configuracoes?.tarifacaoExcedente
                          ?.status && (
                          <>
                            <TextField
                              name={"tarifacaoExcedente-valor"}
                              label={`valor por Mbps`}
                              type="number"
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              value={
                                listTags[index]?.configuracoes
                                  ?.tarifacaoExcedente?.valor
                                  ? listTags[index]?.configuracoes
                                      ?.tarifacaoExcedente?.valor
                                  : null
                              }
                              onChange={(event) => {
                                handleListItensChange(index, event);
                              }}
                              sx={styles.confItensTextField}
                            />
                            <TextField
                              name={"tarifacaoExcedente-limite"}
                              label={`limite de tráfego`}
                              type="number"
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{ shrink: true }}
                              value={
                                listTags[index]?.configuracoes
                                  ?.tarifacaoExcedente?.limite
                                  ? listTags[index]?.configuracoes
                                      ?.tarifacaoExcedente?.limite
                                  : null
                              }
                              onChange={(event) => {
                                handleListItensChange(index, event);
                              }}
                              sx={styles.confItensTextField}
                              helperText={
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    ml: -1.5
                                  }}
                                >
                                  Em Mbps
                                </Typography>
                              }
                            />
                            <TextField
                              name={"tarifacaoExcedente-valorExcedente"}
                              label={`valor do excedente`}
                              type="number"
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              value={
                                listTags[index]?.configuracoes
                                  ?.tarifacaoExcedente?.valorExcedente
                                  ? listTags[index]?.configuracoes
                                      ?.tarifacaoExcedente?.valorExcedente
                                  : null
                              }
                              onChange={(event) => {
                                handleListItensChange(index, event);
                              }}
                              sx={styles.confItensTextField}
                            />
                          </>
                        )}
                      {text.key === "horarioPersonalizado" &&
                        listTags[index]?.configuracoes?.horarioPersonalizado
                          ?.status && (
                          <>
                            <TextField
                              name="horaInicial"
                              label={"hora Inicial"}
                              InputLabelProps={{ shrink: true }}
                              type="time"
                              size="small"
                              value={
                                listTags[index]?.configuracoes
                                  ?.horarioPersonalizado?.horaInicial
                                  ? listTags[index]?.configuracoes
                                      ?.horarioPersonalizado?.horaInicial
                                  : null
                              }
                              onChange={(event) => {
                                handleListItensChange(index, event);
                              }}
                              sx={{
                                ...styles.confItensTextField,
                                input: {
                                  colorScheme:
                                    theme.palette.mode === "light"
                                      ? "light"
                                      : "dark"
                                }
                              }}
                            />
                            <TextField
                              name="horaFinal"
                              label={"Hora Final"}
                              InputLabelProps={{ shrink: true }}
                              type="time"
                              size="small"
                              value={
                                listTags[index]?.configuracoes
                                  ?.horarioPersonalizado?.horaFinal
                                  ? listTags[index]?.configuracoes
                                      ?.horarioPersonalizado?.horaFinal
                                  : null
                              }
                              onChange={(event) => {
                                handleListItensChange(index, event);
                              }}
                              sx={{
                                ...styles.confItensTextField,
                                input: {
                                  colorScheme:
                                    theme.palette.mode === "light"
                                      ? "light"
                                      : "dark"
                                }
                              }}
                            />
                          </>
                        )}
                      {text?.key === "variacaoAbrupta" &&
                        listTags[index]?.configuracoes?.variacaoAbrupta
                          ?.status && (
                          <TextField
                            label={`variacao`}
                            type="number"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              )
                            }}
                            value={
                              listTags[index]?.configuracoes?.variacaoAbrupta
                                ?.variacao
                                ? listTags[index]?.configuracoes
                                    ?.variacaoAbrupta?.variacao
                                : null
                            }
                            onChange={(event) => {
                              handleListItensChange(index, event);
                            }}
                            sx={styles.confItensTextField}
                          />
                        )}
                    </Stack>
                  </Box>
                );
              } else {
                return <></>;
              }
            })}
          </FormGroup>
        </TabPanel>
      ))}
    </TabContext>
  );
}
