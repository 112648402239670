import {
  Paper,
  Stack,
  Typography,
  Button,
  Box,
  Modal,
  useTheme,
  Popper,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import IconTransform from "../../iconsElement";

export default function ListIcon({ props, option }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const mouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const mouseOut = (event) => {
    setAnchorEl(null);
  };
  const styles = {
    triangulo: {
      mt: "25px",
      mr: "30px",
      width: "0",
      height: "0",
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      borderLeft: "10px solid " + theme.palette.background.paperNotification,
    },
    container: {
      backgroundColor: theme.palette.background.paperNotification,
      padding: "10px 15px",
      borderRadius: "3px",
      direction: "column",
      alignItems: "center",
      gap: 0.5,
    },
  };

  return (
    <>
      <li {...props}>
        <Stack id={option.nome} gap={1} direction="row" alignItems="center">
          <Box
            onMouseEnter={mouseEnter}
            onMouseOut={mouseOut}
            sx={{
              width: "22px",
              height: "22px",
              position: "absolute",
            }}
          />

          <IconTransform img={option.img} size="22px" />
          {option.nome.replace("_", "")}
        </Stack>
      </li>
      <Popper
        placement="left"
        sx={{ zIndex: 1500, display: "flex", direction: "row" }}
        id={option.nome + "aa"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <Stack sx={styles.container}>
          <Box sx={{ height: "50px" }}>
            <IconTransform img={option.img} size="50px" />
          </Box>
        </Stack>

        <Box sx={styles.triangulo} />
      </Popper>
    </>
  );
}
