import { useState, useEffect, useId } from "react";
import {
  Modal,
  Paper,
  Stack,
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";

import api from "../../../services/api";
import apiSistema from "../../../services/apiSistema";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const stylesModal = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 3,
    boxShadow: 24,
    padding: "10px",
  },
  campoFiltro: {
    width: "200px",
  },
  campoFiltroSecondary: {
    marginTop: "5.5px",
    firstLetter: {
      textTransform: "capitalize",
    },
    width: "420px",
  },
  button: { height: "40px", minWidth: "120px" },
};

export default function ModalNovaDemanda(props) {
  const { openCreateModal, setOpenCreateModal, setNewRow } = props;
  //dados da linha
  const [responsavel, setResponsavel] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const status = 0;
  const codigo = "";
  const [categoria, setCategoria] = useState("");
  const [subCategoria, setSubCategoria] = useState("");
  const [data, setData] = useState(new Date());
  const [previsao, setPrevisao] = useState(new Date().getTime() + 86400000 * 3);
  const [descricao, setDescricao] = useState("");
  //dados de ususario do localstorage
  const [perfilClienteId, setPerfilClienteId] = useState("");
  const [logsLoginClienteId, setLogsLoginClienteId] = useState("");
  //snackbar
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    let logs = localStorage.getItem("logs");
    if (logs !== "undefined" && logs) {
      logs = JSON.parse(logs);
      setLogsLoginClienteId(logs.id);
    }
  }, []);

  function handleCreateRow(params) {
    let objectRow = {};
    objectRow.id = params.id;
    objectRow.responsavelId = params.responsavel;
    objectRow.solicitanteId = params.solicitante;
    //objectRow.responsavel = params.perfil ? params.perfil : "";
    objectRow.responsavel = params.usuario ? params.usuario.Perfil : "";
    objectRow.solicitante = params.usuarioCliente
      ? params.usuarioCliente.PerfilCliente
      : "";
    objectRow.codigo = params.codigo;
    objectRow.descricao = params.descricao;
    objectRow.categoria = params.categoria;
    objectRow.subCategoria = params.subCategoria;
    objectRow.status = params.status;
    objectRow.previsao = params.previsao;
    objectRow.data = new Date(new Date(params.createAt) - 3 * 60 * 60 * 1000); //correção de fuso horario, com diferença de 3 horas
    objectRow.topicos = 0;
    setNewRow(objectRow);
  }
  function clearModal() {
    setResponsavel("");
    setCategoria("");
    setSubCategoria("");
    setData(new Date());
    setPrevisao(new Date().getTime() + 86400000 * 3);
    setDescricao("");
  }
  async function handleApiPostRelatorios() {
    try {
      //setLoadingPage(true);

      const response = await apiSistema.post("/suporte/create", {
        descricao,
        previsao,
        acessoClientesId: JSON.parse(localStorage.getItem("acessoClienteId")),
        solicitante: JSON.parse(localStorage.getItem("1")).refreshToken
          .usuarioClienteId,
        //logsLoginClienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível buscar dados",
          severity: "error",
        });
        // setLoadingPage(false);
      } else {
        //  setLoadingPage(false);
        if (response.data) {
          handleCreateRow(response.data);
        }
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      //setLoadingPage(false);
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApiPostRelatorios();
      clearModal();
      setOpenCreateModal(false);
      event.stopPropagation();
    }
  };
  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={() => {
          clearModal();
          setOpenCreateModal(false);
        }}
      >
        <Paper sx={stylesModal.paper} elevation={3}>
          <DialogTitle color="primary">{"Nova Demanda"}</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <TextField
              id="input-descricao"
              variant="outlined"
              label="Descrição"
              value={descricao}
              sx={stylesModal.campoFiltroSecondary}
              onChange={(event) => {
                setDescricao(event.target.value);
              }}
              type="text"
              multiline
              rows={4}
              onKeyDown={handleKeyDown}
              inputProps={{ maxLength: 255 }}
              //placeholder="Escreva uma breve descrição da sua demanda"
              helperText="Limite de 255 digitos"
            />
            <Stack direction={"row"} gap={"20px"}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  disabled
                  value={data}
                  onChange={(newValeu) => {
                    setData(newValeu);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={stylesModal.campoFiltro}
                      color="primary"
                      id="data"
                      label="Data de Criação"
                    />
                  )}
                />
              </LocalizationProvider>{" "}
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  disabled
                  value={previsao}
                  onChange={(newValeu) => {
                    setPrevisao(newValeu);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={stylesModal.campoFiltro}
                      color="primary"
                      id="previsao"
                      label="Previsão"
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </Stack>{" "}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              // variant="contained"
              sx={stylesModal.button}
              onClick={() => {
                clearModal();
                setOpenCreateModal(false);
              }}
            >
              CANCELAR
            </Button>{" "}
            <Button
              //  variant="contained"
              sx={stylesModal.button}
              onClick={() => {
                //  handleCreateRow();
                handleApiPostRelatorios();
                clearModal();
                setOpenCreateModal(false);
              }}
            >
              ADICIONAR
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </div>
  );
}
