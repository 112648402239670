import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { Add, Delete } from "@mui/icons-material";
import AlertModal from "../../../../../componentes/alertModal";

export default function EditModalBlocos(params) {
  const {
    setOpenEditModal,
    openEditModal,
    setSnackbar,
    setLoadingTable,
    styles,
    selectedRow,
    setErrorName,
    errorName,
    comparaOutrosNomes,
    handleEditBlocos,
    validarIp,
  } = params;
  const [nome, setNome] = useState("");
  const [listaBloco, setListaBloco] = useState([""]);
  const [disableEditButton, setDisableEditButton] = useState(false);
  //alert modal
  const [confirmarEdicao, setConfirmarEdicao] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const validarIps = (ips) => {
    //const arrayDeIps = ips?.split(",");
    const arrayDeIps = ips;
    let verificaIps = false;
    if (Array.isArray(arrayDeIps) && arrayDeIps.length > 0) {
      verificaIps = arrayDeIps.some((ip) => !validarIp(ip));
    }
    return verificaIps;
  };
  /* const handleHelperText = (ips) => {
    if (ips) {
      return validarIps(ips)
        ? "Formato de algum(s) do(s) IP(s) é inválido"
        : "Os endereços são válidos!";
    }
    return null;
  };*/
  const handleHelperText = (ip) => {
    if (ip) {
      return validarIp(ip) === 1
        ? "O endereço IPv4 é válido!"
        : validarIp(ip) === 2
        ? "O endereço IPv6 é válido!"
        : "Formato de IP inválido";
    }
    return null;
  };
  const handleChangeListaBloco = (e, index) => {
    let auxBloco = [...listaBloco];
    auxBloco[index] = e.target.value.replace(/\s/g, "");
    setListaBloco(auxBloco);
  };
  const addBlock = () => {
    setListaBloco([...listaBloco, ""]);
  };
  const removeBlock = (index) => {
    const newBlock = [...listaBloco];
    newBlock.splice(index, 1);
    setListaBloco(newBlock);
  };
  useEffect(() => {
    if (selectedRow) {
      setNome(selectedRow.nome);
      setListaBloco(selectedRow.blocos ? selectedRow.blocos?.split(",") : [""]);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (
      comparaOutrosNomes(nome, selectedRow.nome) ||
      validarIps(listaBloco) ||
      !listaBloco.every((bloco) => bloco)
    ) {
      setDisableEditButton(true);
    } else {
      setDisableEditButton(false);
    }
  }, [nome, listaBloco]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableEditButton === false) {
      handleApiPutBlocos();
    }
  };
  async function handleApiPutBlocos() {
    try {
      setLoadingTable(true);
      setDisableEditButton(true);
      const response = await api.put("/relatoriosFlow/blocos/update", {
        id: selectedRow.id,
        nome,
        blocos: listaBloco.join(),
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleEditBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableEditButton(false);
      setOpenEditModal(false);
      setConfirmarEdicao(false);
      setOpenAlertModal(false);
    }
  }
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
          setErrorName(false);
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Edite Bloco </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            {" "}
            <Stack direction="column" spacing={"20px"} sx={{ mt: 1 }}>
              <TextField
                sx={styles.TextField}
                id="textfield-nome"
                label="Nome"
                value={nome}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  if (comparaOutrosNomes(e.target.value, selectedRow.nome)) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setNome(e.target.value);
                }}
              />
              {/* <TextField
                sx={styles.TextField}
                id="textfield-blocos"
                label="Bloco(s)"
                
                onKeyDown={handleKeyDown}
                value={listaBloco}
                error={listaBloco ? validarIps(listaBloco) : false}
                helperText={handleHelperText(listaBloco)}
                onChange={(e) =>
                  setListaBloco(e.target.value.replace(/\s/g, ""))
                }
              />*/}
              {listaBloco?.map((bloco, index) => (
                <Stack direction="row" key={index}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="textfield-blocos"
                    label={"Bloco " + (index + 1)}
                    onKeyDown={handleKeyDown}
                    value={bloco}
                    //error={listaBloco ? validarIps(listaBloco) : false}
                    //helperText={handleHelperText(listaBloco)}
                    error={bloco ? !validarIp(bloco) : false}
                    helperText={handleHelperText(bloco)}
                    onChange={(event) => handleChangeListaBloco(event, index)}
                  />{" "}
                  {listaBloco.length > 1 && (
                    <IconButton
                      onClick={() => removeBlock(index)}
                      sx={{ mr: -1 }}
                    >
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                  <IconButton
                    size="small"
                    onClick={addBlock}
                    sx={{ ml: 1, mr: -1 }}
                  >
                    <Add color="primary" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenEditModal(false);
                setErrorName(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableEditButton}
              onClick={() => {
                setOpenAlertModal(true);
                // handleApiPutBlocos();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
      <AlertModal
        openModal={openAlertModal}
        setOpenModal={setOpenAlertModal}
        confirmar={confirmarEdicao}
        handleButton={handleApiPutBlocos}
        severity={"warning"}
        singleButton={false}
      />
    </>
  );
}
