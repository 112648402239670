import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Collapse, Paper, Tab, Tabs, createSvgIcon } from "@mui/material";

import {
  AttachMoney,
  HomeOutlined,
  ManageAccounts,
  MapOutlined,
  MemoryOutlined,
  PictureAsPdfOutlined,
  PlaylistAdd,
  SupportAgentOutlined,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { ReactComponent as IconFlow } from "../../../assets/icons/IconEasyFlow.svg";
import { ReactComponent as IconReport } from "../../../assets/icons/IconEasyReport.svg";
import { ReactComponent as IconBI } from "../../../assets/icons/IconEasyBI.svg";
import { ReactComponent as IconMaps } from "../../../assets/icons/IconEasyMaps.svg";
import { ReactComponent as IconWacth } from "../../../assets/icons/IconEasyWatch.svg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import { useState } from "react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
///teste
const styles = {
  tab: {
    minWidth: "60px",
    minHeight: "48px",
    maxWidth: "200px",
    justifyContent: "start",
    color: "drawer.text",
    //textTransform: "none",
    fontSize: "0.875 rem",
    "&:hover": {
      backgroundColor: "#00000021",
    },
  },
  icons: { fontSize: "22px" },
  smallIcons: { fontSize: "20px", width: "22px" },
};

const EasyFlowIcon = createSvgIcon(<IconFlow />);
const EasyReportIcon = createSvgIcon(<IconReport />);
const EasyBIIcon = createSvgIcon(<IconBI />);
const EasyMapsIcon = createSvgIcon(<IconMaps />);
const EasyWatchIcon = createSvgIcon(<IconWacth />);

export default function SliderBar() {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(0);
  useEffect(() => {
    const pageName = window.location.pathname;
    const pages = [
      "/homeIn",
      "/easyAdd",
      "/easyFlow/relatoriosFlow",
      "/easyReport",
      "/suporte",
      "/iot/shopping",
      "/easyWatch",
    ];
    pages.map((page, index) => {
      if (pageName === page) {
        setValue(index);
      }
    });
  }, []);
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  return (
    <Box display={"flex"}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "drawer.main",
          height: "calc(100vh - 80px)",
          position: "sticky",
          top: "80px",
        }}
      >
        <Collapse in={open} orientation="horizontal" collapsedSize={60}>
          <Box width={!open ? "60px" : "200px"} margin={0} padding={0}>
            <DrawerHeader>
              {!open ? (
                <IconButton onClick={handleDrawerOpen}>
                  <ChevronRightIcon sx={{ color: "drawer.text" }} />
                </IconButton>
              ) : (
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon sx={{ color: "drawer.text" }} />
                </IconButton>
              )}
            </DrawerHeader>
            <Divider />

            <Tabs
              orientation="vertical"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              textColor={"secondary"}
              TabIndicatorProps={{
                style: {
                  background: theme.palette.secondary.main,
                },
              }}
            >
              <Tab
                icon={<HomeOutlined />}
                label={open ? "Home" : ""}
                iconPosition="start"
                value={0}
                sx={styles.tab}
                onClick={(event) => {
                  navigate("/homeIn");
                }}
              />
              {
                <Tab
                  icon={<PlaylistAdd />}
                  label={open ? "EasyAdd" : ""}
                  iconPosition="start"
                  value={1}
                  sx={styles.tab}
                  onClick={() => {
                    navigate("/easyAdd");
                  }}
                />
              }
              <Tab
                icon={<EasyFlowIcon />}
                label={open ? "EasyFlow" : ""}
                iconPosition="start"
                value={2}
                sx={styles.tab}
                onClick={() => {
                  navigate("/easyFlow/relatoriosFlow");
                }}
              />
              <Tab
                icon={<EasyReportIcon sx={styles.icons} />}
                label={open ? "EasyReport" : ""}
                iconPosition="start"
                value={3}
                sx={styles.tab}
                onClick={() => {
                  navigate("/easyReport");
                }}
              />

              <Tab
                icon={<SupportAgentOutlined />}
                label={open ? "Suporte" : ""}
                iconPosition="start"
                value={4}
                sx={styles.tab}
                onClick={() => {
                  navigate("/suporte");
                }}
              />

              <Tab
                icon={<ShoppingCartIcon />}
                iconPosition="start"
                label={open ? "Shop" : ""}
                value={5}
                sx={styles.tab}
                onClick={() => {
                  navigate("/iot");
                }}
              />

              <Tab
                icon={<EasyWatchIcon sx={styles.smallIcons} />}
                iconPosition="start"
                label={open ? "EasyWatch" : ""}
                value={6}
                sx={styles.tab}
                onClick={() => {
                  navigate("/easyWatch");
                }}
              />
              <Divider />
              <Tab
                icon={<DescriptionOutlinedIcon />}
                label={open ? "Documentação" : ""}
                iconPosition="start"
                value={7}
                sx={styles.tab}
                onClick={() => {
                  window.location.href = "https://wiki.mw-solucoes.com";
                }}
              />
            </Tabs>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
}
